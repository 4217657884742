import React, { useEffect, useState } from 'react'
import { useLottie } from 'lottie-react'
import PropTypes from 'prop-types'
import { Icon } from '@mui/material'
import { TooltipStyle } from '../../TradeBlotter.style'

const BloombergConnection = ({ animationData, bloombergConnection }) => {
  
  const [bloombergConnectionState, setBloombergConnectionState] = useState(bloombergConnection)
  
  useEffect(() => {
    if (bloombergConnectionState !== bloombergConnection) {
      setBloombergConnectionState(bloombergConnection)
    }
  }, [bloombergConnection, bloombergConnectionState])

  const options = {
    animationData,
    loop: true,
    autoplay: true,
    speed: 1.5
  }
   
  const { View } = useLottie(options)

  return (
    
          <TooltipStyle
            title={bloombergConnection ? 'Bloomberg Connected' : 'Bloomberg Disconnected'}
            placement="top"
            color="#FF9747"
          >
            <Icon
              style={{
                marginTop: "6.5px",
                width: '50px',
                height: '50px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {View}
            </Icon>
          </TooltipStyle>
  )
}

export default BloombergConnection

BloombergConnection.propTypes = {
  animationData: PropTypes.any,
  bloombergConnection: PropTypes.bool
}
