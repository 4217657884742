export const MODAL_TYPE = {
  ALLOCATE_BLOCK: 'ALLOCATE BLOCK',
  CLIENT: 'CLIENT',
  DEAL: 'DEAL',
  USERS: 'USERS',
  MARKET_MAKERS: 'MARKET MAKERS',
  PRIME_BROKER: 'PRIME BROKER',
  CUTS: 'CUTS',
  CURRENCY_PAIRS: 'CURRENCY PAIRS',
  FIXING_SOURCE: 'FIXING SOURCE',
  TRADE: 'TRADE',
  ENTITIES:'ENTITIES',
  DEAL_REPORT_STATUS: 'DEAL REPORT STATUS'
}

export const MODAL_TITLE = {
  ALLOCATE_BLOCK: 'allocate block',
  NEW_CLIENT: 'new client',
  EDIT_CLIENT: 'edit client',
  NEW_DEAL: 'new deal',
  NEW_MARKET_MAKER: 'new market maker',
  EDIT_MARKET_MAKER: 'edit market maker',
  NEW_USER: 'new user',
  EDIT_USER: 'edit user',
  NEW_PRIME_BROKER: 'new prime broker',
  EDIT_PRIME_BROKER: 'edit prime broker',
  NEW_CUT: 'new cut',
  EDIT_CUT: 'edit cut',
  NEW_CURRENCY_PAIR: 'new currency pair',
  EDIT_CURRENCY_PAIR: 'edit currency pair',
  NEW_FIXING_SOURCE: 'new fixing source',
  EDIT_FIXING_SOURCE: 'edit fixing source',
  NEW_TRADE: 'new trade',
  EDIT_TRADE: 'edit trade',
  ENTITIES:'ENTITIES',
  NEW_MARKET_TRADE: 'new market trade',
  NEW_CLIENT_TRADE: 'new client trade',
  DEAL_REPORT_STATUS: 'deal'
}

export const routes = {
  auth: '/auth',
  authToken: '/auth/token',
  client: '/client',
  counterParty: '/counterparty',
  country: '/country',
  contractDistribution: '/contract/distribution',
  currency: '/currency',
  cut: '/cut',
  currencyPair: '/currency/pair',
  dealExcel: '/deal/excel/',
  dealReportCsv: '/deal/report/csv/',
  dealReportTradeReportCsv: '/deal/report/trade/report/csv/',
  dealTypes: '/deal/type',
  entitlement: '/entitlement',
  fixingSource: '/fixing/source',
  marketMaker: '/market/maker',
  makorCompany: '/makor/company',
  makorPrimeBroker: '/makor/prime/broker',
  productType: '/product/type',
  primeBroker: '/prime/broker',
  primeBrokerMapping: '/prime/broker/mapping',
  primeBrokerGroupEmail: '/prime/broker/group/email',
  entities:"/entities",
  side: '/side',
  origin: '/side',
  city: '/timezone/city',
  tradeBlotter: '/trade/blotter',
  tradeType: '/trade/type',
  tradeTypeGroup: "/trade/type/group",
  tradeDealType: '/trade/deal/type',
  tradeOrigin: '/trade/origin',
  tradeCounterparty: '/trade/counterparty',
  tradeSymbol: '/trade/symbol',
  allocateTradeCounterparty: '/trade/allocate/counterparty',
  trader: '/trader',
  resetPasswordEmail: '/auth/reset/password/email',
  resetPassword: '/auth/reset/password',
  user: '/user',
  addWidget: '/widget/add_widget',
  removeWidget: '/widget/remove_widget',
  getWidget: '/widget'
}

export const ACTION = {
  UPDATE: 'UPDATE',
  CREATE: 'CREATE'
}

export const leToolRoutes = {
  getStatus: '/get_status',
  generateCSV: '/generate_csv',
  getFileNames: '/get_filenames'
}

export const METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
}

export const DEAL_GROUP = {
  SPOT: 'Spot',
  VANILLA_OPTION: 'Vanilla Option',
  DIGITAL_OPTION: 'Digital Option',
  DIGITAL_KNOCK_IN_KNOCK_OUTS: 'Digital Knock-in/Knock-outs',
  DIGITAL_DOUBLES: 'Digital Doubles',
  DOUBLE_NO_TOUCH_OPTION: 'Double No-Touch Option',
  MORE_DOUBLES: 'More Doubles',
  TOUCH_OPTIONS: 'Touch Options',
  ENHANCED_REVERSE_OPTIONS: 'Enhanced Reverse Options',
  REMAINING_OPTIONS: 'Remaining Options'
}


export const PAGES = {
  BOOKING: 'Booking',
  PNL_Attribution: 'P&L Attribution',
  STATIC: 'Static'
}

export const CURRENCY = {
  XDR: 'XDR',
  XUA: 'XUA',
  XSU: 'XSU',
  XAU: 'XAU',
  XPD: 'XPD',
  XPT: 'XPT',
  XAG: 'XAG'
}
export const TRADE_BLOTTER_STATUS = {
  WARNING: 'WARNING',
  NEW: 'NEW',
  NEW_END: 'NEW_END',
  UNION: 'UNION',
  ALLOCATED: 'Allocated',
  UNALLOCATED: 'Unallocated',
  STAGED: 'Staged'
}

export const STAGING_STATUS = {
  LIVE: 'Live',
  STAGED: 'Staged'
}

export const WARNING_MESSAGE = {
  UNGROUPED_TRADE: 'Ungrouped Trade',
  MISMATCHED_QUANTITIES: 'Mismatched quantities',
  DEAL_IS_UNALLOCATED: 'Deal is unallocated',
  DEAL_IS_STAGED: 'Deal is staged'
}

export const DIRECTION = {
  CLIENT: 'Client',
  MARKET_MAKER: 'Market Maker'
}

export const STATUS_CURRENCY_PAIR = {
  UP: 'UP',
  DOWN: 'DOWN',
  SAME: 'SAME'
}

export const SIGN = {
  PREV: 'PREV',
  NEXT: 'NEXT'
}

export const DATE_FORMAT = {
  DEAL_EXCEL_REPORT_DATE: 'YYYY-MM-DD-HH:mm:ss.SSS',
  REGULAR_DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
  REGULAR_DATE: 'YYYY-MM-DD',
  BLOTTER_DATE_TIME: 'YYYY-MMM-DD HH:mm:ss',
  DEAL_STATUS_DATE: 'DD MMM YYYY HH:mm'
}

export const DEAL_TYPE = {
  SPOT: 'Spot',
  VANILLA_OPTION: 'Vanilla Option'

}

export const ERRORS = {
  number: key => `${key.replaceAll('_', ' ')} must be positive number`,
  alpha: key => `${key.replaceAll('_', ' ')} must be upper/lower case letters`,
  alphaNumeric: key => `${key.replaceAll('_', ' ')} must be upper/lower/number case letters`,
  required: key => `${key.replaceAll('_', ' ')} is required`,
  email: key => `${key.replaceAll('_', ' ')} is not valid`,
  least: number => `Choose at least ${number} options`,
  short: (key, number) => `${key.replaceAll('_', ' ')} must be at least ${number} characters`,
  length: (key, number) => `${key.replaceAll('_', ' ')} must be ${number} characters`,
  password: 'incorrect password'
}
