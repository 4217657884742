import { Button, ButtonGroup } from '@mui/material'
import { styled } from '@mui/material/styles'

export const OptionContainer = styled('li')(() => ({
  display: 'flex !important',
  justifyContent: 'space-between !important',
  '&:hover': {
    backgroundColor: 'black'
  }
}))

export const SideButton = styled(Button)(({ theme, select, disabled_button }) => {
  let backgroundColorHover = theme.palette.tabs.backgroundColor.hover
  let backgroundColor = 'transparent'
  let color = theme.palette.button.edit.primary
  let border = `1px solid ${theme.palette.button.edit.primary}`

  if (select === 'true') {
    backgroundColor = theme.palette.button.edit.primary
    color = theme.palette.tabs.backgroundColor.primary
    backgroundColorHover = theme.palette.button.edit.hover
  }

  return {
    flex: 1,
    border,
    borderRadius: '4px',
    color,
    fontSize: '14px',
    backgroundColor,
    '&:hover': {
      border: `1px solid ${theme.palette.button.edit.primary}`,
      backgroundColor: `${backgroundColorHover}`
    },
    '&.MuiButtonBase-root.MuiButton-root.Mui-disabled': {
      backgroundColor: 'transparent',
      color: theme.palette.button.disable,
      border: `1px solid ${theme.palette.button.disable}`
    }
  }
})

export const ButtonGroupStyle = styled(ButtonGroup)(() => ({
  display: 'flex',
  width: '100%'
}))

export const PutCallButton = styled(Button)(({ theme, select }) => {
  let backgroundColorHover = theme.palette.tabs.backgroundColor.hover
  let backgroundColor = "transparent"
  let color = theme.palette.button.text.primary
  let border = `1px solid ${theme.palette.button.text.primary}`
  let pointerEvents

  if (select === 'true') {
    backgroundColor = theme.palette.button.text.primary
    color = theme.palette.tabs.backgroundColor.primary
    backgroundColorHover = theme.palette.button.save.hover
  }

  return {
    flex: 1,
    border,
    borderRadius: '4px',
    color,
    fontSize: '14px',
    backgroundColor,
    pointerEvents,
    '&:hover': {
      border: `1px solid ${theme.palette.button.text.primary}`,
      backgroundColor: `${backgroundColorHover}`
    },
    '&.MuiButtonBase-root.MuiButton-root.Mui-disabled': {
      backgroundColor: 'transparent',
      color: theme.palette.button.disable,
      border: `1px solid ${theme.palette.button.disable}`
    }
  }
})
