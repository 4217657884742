import React from 'react'
import PropTypes from 'prop-types'
import { InputBaseStyle } from '../../../../ModalApp.style'
import { Box } from '@mui/material'
import { Label } from '../../../../../styled/global.style'
import SelectWithArrow from '../../../../../SelectWithArrow/SelectWithArrow'

const DigitalKnockInKnockOutTradeThirdColumn = ({ form, handelFormChange ,disabled = false }) => {
  return (
    <>
      <Box width={'100%'}>
        <Label>Spot Ref *</Label>
        <InputBaseStyle
          name="spot_ref"
          placeholder="0.00"
          value={form.spot_ref}
          disabled={disabled}
          onChange={handelFormChange}
        />
      </Box>
      <Box width={'100%'}>
        <Label>Timing *</Label>
        <SelectWithArrow
          name="timing"
          placeholder="Select Timing"
          disabled={disabled}
          orderType={form.timing}
          onChange={handelFormChange}
          items={[{ name: 'SETTLEMENT' }, { name: 'EXPIRY' }]}
        />
      </Box>
    </>
  )
}

export default DigitalKnockInKnockOutTradeThirdColumn

DigitalKnockInKnockOutTradeThirdColumn.propTypes = {
  disabled:PropTypes.bool,
  handelFormChange: PropTypes.func,
  form: PropTypes.object
}
