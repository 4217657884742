/* eslint-disable */
import React, { useCallback, useState, useRef } from 'react'
import Loader from '../../components/Loader/Loader'
import 'ag-grid-enterprise'
import '../../../node_modules/react-grid-layout/css/styles.css'
import '../../../node_modules/react-resizable/css/styles.css'
import './TradeBlotter.css'
import {
  ContainerRatesWidget,
  ContainerTradeBlotterWidget,
  PaperStyle,
  TypographyStyle
} from './TradeBlotter.style'
import { webSocketWorkerEvent } from '../../services'
import { Box, Grid } from '@mui/material'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail'
import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { ModuleRegistry } from 'ag-grid-community'
import { useDispatch, useSelector } from 'react-redux'
import { handelNotifications } from '../../redux/slices/Notification/notificationSlice'
import { routes, SIGN } from '../../utils/constants'
import { cleanEmptyField } from '../../helper/index'
import BloombergConnection from './components/BloombergConnection/BloombergConnection.js'
import greenPulse from './data/pulse.json'
import orangePulse from './data/pulse_orange.json'
import useTradeBlotterAgGridSetting from './hooks/AgGrid.setting.js'
import useToolBarSetting from './hooks/toolBar.setting.js'
import useBloombergSetting from './hooks/bloomberg.setting.js'
import { Responsive, WidthProvider } from 'react-grid-layout'
import WidgetTradeBlotter from './components/WidgetTradeBlotter/WidgetTradeBlotter.js'
import WidgetRates from './components/WidgetRates/WidgetRates.js'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import useAgGridPriceSettings from './hooks/AgGridPrice.settings.js'
import { defaultLayout } from './data/layout.js'
import { setWidgets } from '../../redux/slices/widgets/WidgetsSlice.js'
import axios from 'axios'
import useTradeBlotterRequestHandler from './hooks/tradeBlotter.request.handler.js'
import TradeBlotterToolBar from './components/TradeBlotterTollbar/TradeBlotterToolBar.js'
const ResponsiveGridLayout = WidthProvider(Responsive)

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  MasterDetailModule,
  MenuModule,
  ClipboardModule,
  ExcelExportModule
])

const TradeBlotter = () => {
  const getWidgets = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_URL.concat(routes.getWidget, '/')

      const result = await axios.get(url, {
        headers: { Authorization: token }
      })
      dispatch(setWidgets(result.data))
    } catch (error) {
      console.log(error)
    }
  }

  const dispatchNotification = (message, type) => {
    dispatch(
      handelNotifications({
        id: Date.now(),
        type,
        message
      })
    )
  }

  const getLayoutFromLS = () => {
    return defaultLayout
  }

  const [data, setData] = useState([])
  const [bloombergConnection, setBloombergConnection] = useState(false)
  const [, setMenuPriceLoading] = useState(true)

  const initialFilterState = useCallback(() => {
    return {
      id: '',
      trade_date_start: '',
      trade_date_end: '',
      value_date_start: '',
      value_date_end: '',
      search: '',
      status_warning: false,
      status_union: false,
      type: [],
      symbol: [],
      prime_broker: [],
      counterparty: [],
      deal_offset: 0,
      trade_offset: 0
    }
  }, [])
  const [dealTradesGroup, setDealTradesGroup] = useState({})
  const [dealTradesAllocateGroup, setDealTradesAllocatedGroup] = useState({})
  const [tradesGroup, setTradesGroup] = useState({})
  const [dealsGroup, setDealsGroup] = useState({})
  const [pendingData, setPendingData] = useState({ data: [], status: null })
  const [dealsGiveUpGroup, setDealsGiveUpGroup] = useState({})
  const gridRef = useRef(null)
  const gridScrollHight = useRef({ top: 0 })
  const [page, setPage] = useState(1)
  const [disabled, setDisabled] = useState({ next: false, prev: true })
  const [pagination, setPagination] = useState([{}])
  const [filters, setFilters] = useState(initialFilterState())
  const [loading, setLoading] = useState(true)
  const [tradeBlotterResponse, setTradeBlotterResponse] = useState({ status: '', data: [] })
  const [selected, setSelected] = useState(0)
  const [selectedAllDeal, setSelectedAllDeal] = useState({
    status: false,
    maxDeals: 0,
    signDeals: 0
  })
  const [checkBoxHeader, setCheckBoxHeader] = useState(false)
  const [openPrice, setOpenPrice] = useState(false)
  const token = useSelector(state => state.auth.token)
  const dispatch = useDispatch()
  const storage = getLayoutFromLS()
  const [layouts, setLayouts] = useState(storage)
  const bloombergPriceSnapshot = useSelector(state => state.bloomberg.data)
  const widgets = useSelector(state => state.widgets.widgets)
  const widgetPreferences = widgets.map(widget => widget.preferences)

  useTradeBlotterRequestHandler({
    gridScrollHight,
    tradeBlotterResponse,
    filters,
    selected,
    disabled,
    page,
    tradesGroup,
    dealsGroup,
    pendingData,
    setTradeBlotterResponse,
    setPendingData,
    setLoading,
    setDisabled,
    dealTradesGroup,
    getWidgets,
    setPagination,
    setDealsGroup,
    setTradesGroup,
    setDealsGiveUpGroup,
    setDealTradesGroup,
    setDealTradesAllocatedGroup,
    setSelected,
    selectedAllDeal,
    setSelectedAllDeal,
    dispatchNotification
  })

  const { defaultColDef2, columnsDefs2, getRowId2 } = useAgGridPriceSettings({
    setOpenPrice,
    getLayoutFromLS,
    openPrice,
    setLayouts,
    defaultLayout
  })

  const {
    onRowClicked,
    columnsDefs,
    defaultColDef,
    isRowMaster,
    getRowId,
    onRowDoubleClicked,
    detailCellRenderer,
    getRowStyle,
    getContextMenuItems,
    onBodyScroll
  } = useTradeBlotterAgGridSetting({
    selected,
    setSelected,
    gridRef,
    dealTradesGroup,
    setDealTradesGroup,
    cleanCheckBoxState,
    dealTradesAllocateGroup,
    setDealTradesAllocatedGroup,
    dealsGiveUpGroup,
    setDealsGiveUpGroup,
    tradesGroup,
    setTradesGroup,
    dealsGroup,
    setDealsGroup,
    dispatch,
    token,
    pendingData,
    tradeBlotterResponse,
    gridScrollHight,
    setTradeBlotterResponse,
    setPendingData,
    selectedAllDeal,
    setSelectedAllDeal,
    checkBoxHeader,
    setCheckBoxHeader
  })

  const {
    handelAllocateTrade,
    handelTradeDealDelete,
    handelGroupButtonRender,
    handelDealGiveUp,
    handelUnGroupDeals,
    handelAmendTrade,
    handelGroupTrade,
    disabledGroupTrade,
    disabledAllocateTrade,
    disabledUnGroupDeal,
    disabledAmendTrade,
    disabledDealGiveUp,
    disabledDeleteTradeDeal
  } = useToolBarSetting({
    dealsGroup,
    tradesGroup,
    dealTradesAllocateGroup,
    dealsGiveUpGroup,
    dealTradesGroup,
    dispatchNotification
  })

  useBloombergSetting({
    tradeBlotterResponse,
    data,
    setData,
    setMenuPriceLoading,
    setBloombergConnection,
    bloombergPriceSnapshot
  })

  const handelGlobalFilterIcon = name => {
    const copyFilters = JSON.parse(JSON.stringify(filters))
    if (name === 'status_warning') {
      copyFilters.status_warning = !copyFilters.status_warning
    }
    if (name === 'status_union') {
      copyFilters.status_union = !copyFilters.status_union
    }

    setFilters(copyFilters)
    cleanCheckBoxState()
    cleanEmptyField(copyFilters)
    cleanPaginationFilter(copyFilters)
    webSocketWorkerEvent.sendEvent({ type: 'get_trade_blotter', data: copyFilters })
  }

  const handelGlobalSearchFilter = event => {
    const { value } = event.target
    setFilters(filters => ({ ...filters, search: value }))
  }

  const handelKeyPress = event => {
    if (event.code === 'Enter') {
      const copyFilters = JSON.parse(JSON.stringify(filters))
      cleanCheckBoxState()
      cleanEmptyField(copyFilters)
      cleanPaginationFilter(copyFilters)
      webSocketWorkerEvent.sendEvent({ type: 'get_trade_blotter', data: copyFilters })
    }
  }

  function cleanCheckBoxState() {
    if (Object.keys(tradesGroup).length !== 0) {
      setTradesGroup({})
    }
    if (Object.keys(dealTradesGroup).length !== 0) {
      setDealTradesGroup({})
    }
    if (Object.keys(dealsGroup).length !== 0) {
      setDealsGroup({})
    }
    if (Object.keys(dealTradesAllocateGroup).length !== 0) {
      setDealTradesAllocatedGroup({})
    }
    if (Object.keys(dealsGiveUpGroup).length !== 0) {
      setDealsGiveUpGroup({})
    }
    if (page !== 1) {
      setPage(1)
    }
    if (selected !== 0) {
      setSelected(0)
    }
    if (selectedAllDeal.status || selectedAllDeal.signDeals > 0) {
      setSelectedAllDeal({ ...selectedAllDeal, status: false, signDeals: 0 })
    }
    if (checkBoxHeader) {
      setCheckBoxHeader(false)
    }
  }

  const cleanPaginationFilter = useCallback(() => {
    setPage(1)
    setPendingData({ data: [], status: null })
    setDisabled({ prev: true, next: false })
  }, [])

  const handelPagination = sign => {
    const paginationCall = sign === SIGN.NEXT ? pagination[page] : pagination[page - 2]
    const newPage = sign === SIGN.NEXT ? page + 1 : page - 1
    if (newPage !== 1) {
      setDisabled({ prev: false, next: false })
    }
    setPage(newPage)

    let data = {
      ...filters,
      ...paginationCall
    }
    cleanEmptyField(data)
    cleanCheckBoxState()
    webSocketWorkerEvent.sendEvent({
      type: 'get_trade_blotter',
      data: data
    })
    setLoading(true)
  }

  const onLayoutChange = (layout, layouts) => {
    setLayouts(layouts)
  }

  const removeWidgetToUserPreference = async val => {
    try {
      const url = process.env.REACT_APP_SERVER_URL.concat(routes.removeWidget, '/')
      const result = await axios.post(
        url,
        { val },
        {
          headers: { Authorization: token }
        }
      )
      dispatch(setWidgets(result.data))
    } catch (error) {
      console.log(error)
    }
  }

  const handleRemoveWidget = async val => {
    if (val === 'Trade Blotter') {
      removeWidgetToUserPreference('Trade Blotter')
    } else {
      removeWidgetToUserPreference('Rates')
    }
  }

  const widgetHandler = () => {
    return (
      <>
        {widgetPreferences.includes('Trade Blotter') && !widgetPreferences.includes('Rates') && (
          <WidgetTradeBlotter
            handelPagination={handelPagination}
            handleRemoveWidget={handleRemoveWidget}
            gridRef={gridRef}
            columnsDefs={columnsDefs}
            detailCellRenderer={detailCellRenderer}
            defaultColDef={defaultColDef}
            getRowStyle={getRowStyle}
            tradeBlotterResponse={tradeBlotterResponse}
            getRowId={getRowId}
            onBodyScroll={onBodyScroll}
            onRowClicked={onRowClicked}
            onRowDoubleClicked={onRowDoubleClicked}
            getContextMenuItems={getContextMenuItems}
            isRowMaster={isRowMaster}
            page={page}
            disabled={disabled}
          >
            <TradeBlotterToolBar
              handelGroupButtonRender={handelGroupButtonRender}
              handelGroupTrade={handelGroupTrade}
              disabledGroupTrade={disabledGroupTrade}
              handelUnGroupDeals={handelUnGroupDeals}
              disabledUnGroupDeal={disabledUnGroupDeal}
              handelAmendTrade={handelAmendTrade}
              handelKeyPress={handelKeyPress}
              disabledAmendTrade={disabledAmendTrade}
              handelAllocateTrade={handelAllocateTrade}
              disabledAllocateTrade={disabledAllocateTrade}
              handelDealGiveUp={handelDealGiveUp}
              disabledDealGiveUp={disabledDealGiveUp}
              handelTradeDealDelete={handelTradeDealDelete}
              disabledDeleteTradeDeal={disabledDeleteTradeDeal}
              filters={filters}
              setFilters={setFilters}
              handelGlobalFilterIcon={handelGlobalFilterIcon}
              page={page}
              setPage={setPage}
              cleanCheckBoxState={cleanCheckBoxState}
              handelGlobalSearchFilter={handelGlobalSearchFilter}
            />
          </WidgetTradeBlotter>
        )}
        {widgetPreferences.includes('Rates') && !widgetPreferences.includes('Trade Blotter') && (
          <WidgetRates
            handleRemoveWidget={handleRemoveWidget}
            columnsDefs2={columnsDefs2}
            data={data}
            getRowId2={getRowId2}
            defaultColDef2={defaultColDef2}
          />
        )}
        {((widgetPreferences.includes('Rates') && widgetPreferences.includes('Trade Blotter')) ||
          (!widgetPreferences.includes('Rates') &&
            !widgetPreferences.includes('Trade Blotter'))) && (
          <PaperStyle>
            <Grid container spacing={2}>
              <ContainerTradeBlotterWidget item xl={9.6} lg={9} md={8} sm={12} xs={12}>
                <WidgetTradeBlotter
                  handelPagination={handelPagination}
                  handleRemoveWidget={handleRemoveWidget}
                  gridRef={gridRef}
                  columnsDefs={columnsDefs}
                  detailCellRenderer={detailCellRenderer}
                  defaultColDef={defaultColDef}
                  getRowStyle={getRowStyle}
                  tradeBlotterResponse={tradeBlotterResponse}
                  getRowId={getRowId}
                  onBodyScroll={onBodyScroll}
                  onRowClicked={onRowClicked}
                  onRowDoubleClicked={onRowDoubleClicked}
                  getContextMenuItems={getContextMenuItems}
                  isRowMaster={isRowMaster}
                  page={page}
                  disabled={disabled}
                >
                  <TradeBlotterToolBar
                    handelGroupButtonRender={handelGroupButtonRender}
                    handelKeyPress={handelKeyPress}
                    handelGroupTrade={handelGroupTrade}
                    disabledGroupTrade={disabledGroupTrade}
                    handelUnGroupDeals={handelUnGroupDeals}
                    disabledUnGroupDeal={disabledUnGroupDeal}
                    handelAmendTrade={handelAmendTrade}
                    disabledAmendTrade={disabledAmendTrade}
                    handelAllocateTrade={handelAllocateTrade}
                    disabledAllocateTrade={disabledAllocateTrade}
                    handelDealGiveUp={handelDealGiveUp}
                    disabledDealGiveUp={disabledDealGiveUp}
                    handelTradeDealDelete={handelTradeDealDelete}
                    disabledDeleteTradeDeal={disabledDeleteTradeDeal}
                    filters={filters}
                    setFilters={setFilters}
                    handelGlobalFilterIcon={handelGlobalFilterIcon}
                    page={page}
                    setPage={setPage}
                    cleanCheckBoxState={cleanCheckBoxState}
                    handelGlobalSearchFilter={handelGlobalSearchFilter}
                  />
                </WidgetTradeBlotter>
              </ContainerTradeBlotterWidget>
              <ContainerRatesWidget item xl={2.4} lg={3} md={4} sm={12} xs={12}>
                <WidgetRates
                  handleRemoveWidget={handleRemoveWidget}
                  columnsDefs2={columnsDefs2}
                  data={data}
                  getRowId2={getRowId2}
                  defaultColDef2={defaultColDef2}
                />
              </ContainerRatesWidget>
            </Grid>
          </PaperStyle>
        )}
      </>
    )
  }

  return (
    <PaperStyle className={'ag-theme-quartz-dark'}>
      {loading && <Loader />}
      {!loading && tradeBlotterResponse.status === 'ok' && widgetHandler()}
      {!loading && (
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            position: 'fixed',
            bottom: '0%',
            marginTop: '100px',
            right: '0.2%'
          }}
        >
          {!bloombergConnection ? <TypographyStyle>Using indicative rates</TypographyStyle> : null}
          <BloombergConnection
            animationData={bloombergConnection ? greenPulse : orangePulse}
            bloombergConnection={bloombergConnection}
          />
        </Box>
      )}
    </PaperStyle>
  )
}

export default TradeBlotter
