/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import * as echarts from 'echarts'
import { Grid } from '@mui/material'
import { ButtonType, TypographyStyle } from './MultiLineChart.style'
import ReactECharts from 'echarts-for-react'

const MultiLineChart = () => {
  const [dateRange, setDateRange] = useState('6M')
  const seriesData = {
    '1D': ['Jan'],
    '1W': ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    '1M': Array.from({ length: 30 }, (_, i) => `Day ${i + 1}`),
    '3M': ['Jan', 'Feb', 'Mar'],
    '6M': ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
    ALL: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  }

  const getChartOptions = () => ({
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      right: '10%',
      top: '0%',
      textStyle: {
        color: '#ccc',
        fontSize: 12
      },
      data: ['RBS BOA', 'RBS AWFB', 'RBS EDGE BOA']
    },
    xAxis: {
      type: 'category',
      data: seriesData[dateRange],
      axisLabel: { color: '#ccc' },
      axisLine: { lineStyle: { color: '#666' } }
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: 100,
      axisLabel: { color: '#ccc' },
      splitLine: { lineStyle: { color: '#666' } },
      axisLine: { lineStyle: { color: '#666' } }
    },
    series: [
      {
        name: 'RBS BOA',
        type: 'line',
        data: [20, 40, 60, 80, 70, 50, 30],
        smooth: true,
        lineStyle: {
          color: '#3398DB'
        }
      },
      {
        name: 'RBS EDGE BOA',
        type: 'line',
        data: [10, 50, 30, 90, 60, 40, 70],
        smooth: true,
        lineStyle: {
          color: '#c23531'
        }
      },
      {
        name: 'RBS AWFB',
        type: 'line',
        data: [60, 30, 70, 20, 50, 90, 40],
        smooth: true,
        lineStyle: {
          color: '#2f4554'
        }
      }
    ],
    grid: {
      left: '4%',
      right: '8%',
      bottom: '15%'
    }
  })
  return (
    <Grid container style={{ width: '100%', flexDirection: 'column' }} className="container">
      <Grid
        item
        style={{ display: 'flex', gap: '10px', alignItems: 'center', width: 'fit-content' }}
      >
        <TypographyStyle>Zoom:</TypographyStyle>
        <ButtonType onClick={() => setDateRange('1D')}>1D</ButtonType>
        <ButtonType onClick={() => setDateRange('1W')}>1W</ButtonType>
        <ButtonType onClick={() => setDateRange('1M')}>1M</ButtonType>
        <ButtonType onClick={() => setDateRange('3M')}>3M</ButtonType>
        <ButtonType onClick={() => setDateRange('6M')}>6M</ButtonType>
        <ButtonType onClick={() => setDateRange('ALL')}>ALL</ButtonType>
      </Grid>
      <ReactECharts
        option={getChartOptions()}
        style={{ width: '768px', height: '328px' }}
      />
      <Grid
        item
        style={{
          color: '#FFF',
          fontSize: '16px',
          marginTop: '16px',
          textAlign: 'center'
        }}
      >
        Average Daily Volume / Trading Account
      </Grid>
    </Grid>
  )
}

export default MultiLineChart
// const chartRef = useRef(null);
// const [dateRange, setDateRange] = useState('6M');

// // Sample data for each range
// const data = {
//   '1D': ['oct'],
//   '1W': ['oct'],
//   '1M': ['sep', 'oct'],
//   '3M': ['aug', 'sep', 'oct'],
//   '6M': ['may', 'jun', 'jul', 'aug', 'sep', 'oct'],
//   'ALL': ['may', 'jun', 'jul', 'aug', 'sep', 'oct']
// };

// const seriesData = {
//   '1D': [[90], [50], [80]],
//   '1W': [[90], [50], [80]],
//   '1M': [[60, 90], [70, 50], [30, 80]],
//   '3M': [[30, 60, 90], [20, 70, 50], [50, 30, 80]],
//   '6M': [[60, 40, 80, 30, 60, 90], [80, 60, 100, 20, 70, 50], [40, 20, 90, 50, 30, 80]],
//   'ALL': [[60, 40, 80, 30, 60, 90], [80, 60, 100, 20, 70, 50], [40, 20, 90, 50, 30, 80]]
// };

// useEffect(() => {
//   const myChart = echarts.init(chartRef.current);

//   const option = {
//     tooltip: {
//       trigger: 'axis'
//     },
//     legend: {
//     top:"0%",
//       right: '10%',
//       textStyle: {
//         color: '#ccc',
//         fontSize:"12px"
//       },
//         data: ['RBS BOA', 'RBS AWFB', 'RBS EDGE BOA'],
//     },
//     xAxis: {
//       type: 'category',
//       data: data[dateRange],
//       axisLabel: {
//         color: '#ccc'
//       },
//       axisLine: {
//         lineStyle: {
//           color: '#666'
//         }
//       }
//     },
//     yAxis: {
//       type: 'value',
//       min: 0,
//       max: 100,
//       axisLabel: {
//         color: '#ccc'
//       },
//       splitLine: {
//         lineStyle: {
//           color: '#666'
//         }
//       },
//       axisLine: {
//         lineStyle: {
//           color: '#666'
//         }
//       }
//     },
//     series: [
//       {
//         name: 'RBS BOA',
//         type: 'line',
//         data: seriesData[dateRange][0],
//         symbol: 'circle',
//         symbolSize: 8,
//         lineStyle: {
//           color: '#5470c6'
//         },
//         itemStyle: {
//           color: '#5470c6'
//         }
//       },
//       {
//         name: 'RBS AWFB',
//         type: 'line',
//         data: seriesData[dateRange][1],
//         symbol: 'circle',
//         symbolSize: 8,
//         lineStyle: {
//           color: '#91cc75'
//         },
//         itemStyle: {
//           color: '#91cc75'
//         }
//       },
//       {
//         name: 'RBS EDGE BOA',
//         type: 'line',
//         data: seriesData[dateRange][2],
//         symbol: 'circle',
//         symbolSize: 8,
//         lineStyle: {
//           color: '#fac858'
//         },
//         itemStyle: {
//           color: '#fac858'
//         }
//       }
//     ],
//     grid: {
//       left: '5%',
//       right: '10%',
//       bottom: '15%'
//     },
//     backgroundColor: 'transparent'  // Dark background
//   };

//   myChart.setOption(option);

//   const handleResize = () => {
//     myChart.resize();
//   };
//   window.addEventListener('resize', handleResize);

//   return () => {
//     window.removeEventListener('resize', handleResize);
//     myChart.dispose();
//   };
// }, [dateRange]);

// return (
// <Grid>
//   <Grid container>
//      <TypographyStyle>Zoom: </TypographyStyle>
//     <ButtonType onClick={() => setDateRange('1D')}>1D</ButtonType>
//     <ButtonType onClick={() => setDateRange('1W')}>1W</ButtonType>
//     <ButtonType onClick={() => setDateRange('1M')}>1M</ButtonType>
//     <ButtonType onClick={() => setDateRange('3M')}>3M</ButtonType>
//     <ButtonType onClick={() => setDateRange('6M')}>6M</ButtonType>
//     <ButtonType onClick={() => setDateRange('ALL')}>ALL</ButtonType>
//   </Grid>
//   <div ref={chartRef} style={{ width:"778px", height: '368px' }} />

//   <Grid style={{color:"var(--Primary-text, #FFF)",fontSize:"16px",marginTop:"16px",textAlign:"center"}}>Average Daily Volume / Trading Account</Grid>
// </Grid>
// );
