/* eslint-disable */
import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
  ListItem,
  ListItemText
} from '@mui/material'
import validator from 'validator'
import { ReactComponent as CheckIcon } from '../../../assets/Checkbox_checked.svg'
import { AddIconStyle } from '../../../components/ModalApp/children/Client/Client.style'
import { ReactComponent as Checked } from '../../../assets/Checkbox_checked.svg'
import { ReactComponent as UnChecked } from '../../../assets/Checkbox_unchecked.svg'
import { DeleteIconStyle } from '../../../components/styled/global.style'
import {
  Typography1Style,
  Typography2Style,
  Typography3Style,
  Typography4Style,
  Typography5Style,
  Typography7Style,
  CloseButton,
  Typography9Style,
  Typography10Style,
  NavButton,
  StyleInputLabel,
  StyleTextField,
  LuceraContainer,
  SaveButton,
  StyleSelect,
  IOSSwitch,
  RadioStyle,
  TextFieldStyle
} from './ClientMmDetails.style'
import { routes } from '../../../utils/constants'
import useAxios from '../../../hooks/useAxios'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { handelNotifications } from '../../../redux/slices/Notification/notificationSlice'
import currencyList from '../../../utils/currencyList.json'
import { CheckboxEntities, FormControlLabelStyle } from '../Entities.style'

function ClientMmDetails({ clientMmId, userType }) {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const [search, setSearch] = useState({ search: undefined })
  const [form, setForm] = useState({
    lucera_drop_value: new Array(3).fill({ type: '', lucera: '' }),
    emails: [],
    pbMapping: '',
    makorPb: '',
    is_structured: true,
    client_name: ''
  })
  const [active, setActive] = useState(true)
  const [role, setRole] = useState('Client')
  const [entity, setEntity] = useState('Makor Securities London')
  const [giveUp, setGiveUp] = useState(true)
  const [email, setEmail] = useState('')
  const [activeButton, setActiveButton] = useState('Spot')
  const [currencyPermissionData, setCurrencyPermissionData] = useState([])
  const buttonLabels = ['Spot', 'Forwards', 'Vanilla Options', 'Exotic Options', 'Swap']
  const request = {
    endPoint: routes.contractDistribution,
    params: search
  }
  const { response: contractDistribution, setResponse: setResponseContractDistribution } =
    useAxios(request)
  const [currencyChange, seCurrencyChange] = useState([])
  const [currencyPermissionChange, seCurrencyPermissionChange] = useState({})

  const getMarketMakerById = async () => {
    const url = process.env.REACT_APP_SERVER_URL.concat(routes.marketMaker, '/', clientMmId)
    try {
      const result = await axios.get(url, { headers: { Authorization: token } })
      const updatedLuceraDropValue = result.data.lucera_drop_value
      setForm(prevForm => ({
        ...prevForm,
        lucera_drop_value: updatedLuceraDropValue,
        client_name: result.data.name,
        makorCompany: result.data.makor_company,
        emails: result.data.market_maker_mailing_list,
        pbMapping: result.data.prime_broker_mapping,
        makorPb: result.data.makor_prime_broker
      }))
      if (result.data.is_structured === 0) {
        setForm(prevForm => ({
          ...prevForm,
          is_structured: false
        }))
      } else {
        setForm(prevForm => ({
          ...prevForm,
          is_structured: true
        }))
      }
    } catch (error) {
      console.log('error')
    }
  }

  const getClientById = async () => {
    const url = process.env.REACT_APP_SERVER_URL.concat(routes.client, '/', clientMmId)
    try {
      const result = await axios.get(url, { headers: { Authorization: token } })

      const updatedLuceraDropValue = result.data.lucera_drop_value
      setForm(prevForm => ({
        ...prevForm,
        lucera_drop_value: updatedLuceraDropValue,
        client_name: result.data.name,
        makorCompany: result.data.makor_company,
        emails: result.data.client_mailing_list,
        pbMapping: result.data.prime_broker_mapping,
        makorPb: result.data.makor_prime_broker
      }))
      if (result.data.is_structured === 0) {
        setForm(prevForm => ({
          ...prevForm,
          is_structured: false
        }))
      } else {
        setForm(prevForm => ({
          ...prevForm,
          is_structured: true
        }))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleCheckboxChange = email => {
    setForm(prevForm => ({
      ...prevForm,
      emails: prevForm.emails.includes(email)
        ? prevForm.emails.filter(e => e !== email) // Remove email if unchecked
        : [...prevForm.emails, email] // Add email if checked
    }))
  }

  const addLuceraRows = () => {
    const copyForm = JSON.parse(JSON.stringify(form))
    if (!copyForm.lucera_drop_value.every(lucera => lucera.type && lucera.lucera)) return
    copyForm.lucera_drop_value.push({ type: '', lucera: '', status: true })
    setForm(copyForm)
  }

  const submitNewContract = () => {
    const copyForm = JSON.parse(JSON.stringify(form))
    if (form?.emails.includes(email)) {
      return
    }
    if (validator.isEmail(email)) {
      const newContractDistributionList = {
        data: [...contractDistribution.data, { email }],
        status: contractDistribution.status
      }

      setResponseContractDistribution(newContractDistributionList)
      copyForm.emails.push(email)
      setForm(copyForm)
      setEmail('')
      setSearch({ search: undefined })
    }
  }

  const submit = async () => {
    const urlClient = process.env.REACT_APP_SERVER_URL.concat(routes.client, '/', clientMmId)
    const urlMm = process.env.REACT_APP_SERVER_URL.concat(routes.marketMaker, '/', clientMmId)
    try {
      let luceraValue = form.lucera_drop_value
      const hasLuceraEmptyValues = luceraValue.some(item => item.type === '' || item.lucera === '')

      if (hasLuceraEmptyValues) {
        dispatch(
          handelNotifications({
            id: Date.now(),
            type: 'error',
            message: 'Lucera value cannot be empty'
          })
        )
        return
      }

      let result
      if (userType === 'Client') {
        result = await axios.put(urlClient, { form }, { headers: { Authorization: token } })
      } else {
        result = await axios.put(urlMm, { form }, { headers: { Authorization: token } })
      }
    } catch (error) {
      if (Array.isArray(error?.response?.data))
        for (const { message } of error.response.data) {
          dispatch(
            handelNotifications({
              id: Date.now(),
              type: 'error',
              message: message
            })
          )
        }
    }
  }

  const submitPermission = async () => {
    const getMissingOrIds = findMissingCurrencies(currencyChange)
    if (getMissingOrIds.error === true) {
      dispatch(
        handelNotifications({
          id: Date.now(),
          type: 'error',
          message: getMissingOrIds.message
        })
      )
      seCurrencyChange([])
    } else {
      let type

      if (activeButton === 'Spot') {
        type = 1
      } else if (activeButton === 'Forwards') {
        type = 2
      } else if (activeButton === 'Vanilla Options') {
        type = 4
      } else if (activeButton === 'Exotic Options') {
        type = 5
      } else if (activeButton === 'Swap') {
        type = 3
      }
      if (userType === 'Client') {
        const tenorLimitId = currencyPermissionChange.tenor_limit_id
        let url = process.env.REACT_APP_SERVER_URL.concat(
          routes.client,
          '/',
          clientMmId,
          '/',
          type,
          '/',
          tenorLimitId
        )
        const response = await axios.put(url, getMissingOrIds.currencyIds, {
          headers: { Authorization: token }
        })
      } else {
        const tenorLimitId = currencyPermissionChange.tenor_limit_id
        let url = process.env.REACT_APP_SERVER_URL.concat(
          routes.marketMaker,
          '/',
          clientMmId,
          '/',
          type,
          '/',
          tenorLimitId
        )
        const response = await axios.put(url, getMissingOrIds.currencyIds, {
          headers: { Authorization: token }
        })
      }
    }
  }
  const handelLuceraDropValue = (index, name, value) => {
    const copyForm = JSON.parse(JSON.stringify(form))

    if (name === 'lucera_type') {
      copyForm.lucera_drop_value[index].type = value
    } else if (name === 'lucera_value') {
      copyForm.lucera_drop_value[index].lucera = value
    }
    setForm(copyForm)
  }

  const handleDeleteLuceraValue = (type, lucera, index) => {
    setForm(prevForm => ({
      ...prevForm,
      lucera_drop_value: prevForm.lucera_drop_value.filter((key, i) => i !== index)
    }))
  }

  const handelContractDistribution = event => {
    setEmail(event.target.value)
    setSearch({ search: event.target.value })
  }

  const filteredResults = contractDistribution?.data?.filter(item => {
    if (item !== undefined && search.search !== undefined) {
      return item?.email?.toLowerCase().includes(search.search.toLowerCase())
    }
  })

  const emails = filteredResults?.map(item => item.email)

  const handleNavButtonClick = async (buttonName, userType) => {
    let type
    setActiveButton(buttonName)
    if (buttonName === 'Spot') {
      type = 1
    } else if (buttonName === 'Forwards') {
      type = 2
    } else if (buttonName === 'Vanilla Options') {
      type = 4
    } else if (buttonName === 'Exotic Options') {
      type = 5
    } else if (buttonName === 'Swap') {
      type = 3
    }
    let url
    if (userType === 'Client') {
      url = process.env.REACT_APP_SERVER_URL.concat(routes.client, '/', clientMmId, '/', type)
    } else {
      url = process.env.REACT_APP_SERVER_URL.concat(routes.marketMaker, '/', clientMmId, '/', type)
    }
    try {
      const response = await axios.get(url, { headers: { Authorization: token } })
      setCurrencyPermissionData(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const findMissingCurrencies = currenciesToCheck => {
    const availableCurrenciesMap = new Map(currencyList.map(item => [item.name, item.id]))
    const missingCurrencies = currenciesToCheck.filter(
      currency => !availableCurrenciesMap.has(currency)
    )

    if (missingCurrencies.length > 0) {
      return {
        error: true,
        message: `The following currencies do not exist: ${missingCurrencies.join(', ')}`,
        missingCurrencies: missingCurrencies
      }
    } else {
      const currencyIds = currenciesToCheck.map(currency => availableCurrenciesMap.get(currency))
      return {
        error: false,
        message: 'All currencies exist',
        currencyIds: currencyIds
      }
    }
  }

  const handleTenorChange = (e, tenor) => {
    seCurrencyPermissionChange(tenor)
    const currencyArray = Array.isArray(e.target.value)
      ? newCurrencies
      : e.target.value.split(',').map(curr => curr.trim())

    const updatedData = currencyPermissionData.map(item => {
      if (item.tenor_name === tenor.tenor_name) {
        return {
          ...item,
          currencies: currencyArray
        }
      }
      return item
    })
    seCurrencyChange(currencyArray)
    setCurrencyPermissionData(updatedData)
  }

  useEffect(() => {
    if (userType === 'Client') {
      setRole('Client')
      getClientById()
      handleNavButtonClick('Spot', userType)
    } else {
      setRole('Market Maker')
      getMarketMakerById()
      handleNavButtonClick('Spot', userType)
    }
  }, [clientMmId, userType])

  return (
    <Grid container style={{ gap: '10px' }}>
      <Grid item xs={12}>
        {/* Header */}
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography2Style>{form?.client_name}</Typography2Style>
          <FormControlLabel
            control={<IOSSwitch checked={active} onChange={() => setActive(!active)} />}
            label={<Typography1Style>Active</Typography1Style>}
          />
        </Grid>

        <Grid item xs={12} style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
          <Typography4Style>{form?.makorPb}</Typography4Style>
          <Typography3Style>{form?.makorCompany}</Typography3Style>
        </Grid>

        {/* Main */}

        <Grid
          container
          style={{ justifyContent: 'space-between', flexWrap: 'wrap', marginTop: '12px' }}
        >
          <Grid
            item
            xs={3.5}
            style={{
              height: '320px',
              maxHeight: '330px',
              overflowY: 'auto',
              overflowX: 'clip'
            }}
            className="container"
          >
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexFlow: 'wrap',
                alignItems: 'center',
                paddingTop: '8px',
                paddingLeft: '10px',
                marginTop: '12px'
              }}
            >
              <RadioGroup
                value={role}
                // onChange={e => setRole(e.target.value)}
                row
              >
                <Grid>
                  <FormControlLabel
                    value="Client"
                    control={<RadioStyle />}
                    label={
                      <Typography
                        style={{
                          color: 'var(--Primary-text, #FFF)',
                          leadingTrim: 'both',
                          textEdge: 'cap',
                          fontFamily: 'Nunito',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          marginRight: '32px',
                          fontWeight: '500',
                          lineHeight: '1'
                        }}
                      >
                        Client
                      </Typography>
                    }
                  />
                </Grid>
                <Grid>
                  <FormControlLabel
                    value="Market Maker"
                    control={<RadioStyle />}
                    label={
                      <Typography
                        style={{
                          color: 'var(--Primary-text, #FFF)',
                          leadingTrim: 'both',
                          textEdge: 'cap',
                          fontFamily: 'Nunito',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: '500',
                          lineHeight: '1'
                        }}
                      >
                        Market Maker
                      </Typography>
                    }
                  />
                </Grid>
              </RadioGroup>
            </Grid>

            <Grid item xs={12} style={{ display: 'flex', marginTop: '24px' }} class="primeBroker">
              <Typography5Style>Prime Broker / Mapping Value</Typography5Style>
            </Grid>

            <Grid
              container
              xs={12}
              className="container"
              style={{ display: 'flex', marginTop: '8px', gap: '16px' }}
            >
              <Grid item xs={4} style={{ lineHeight: 1, flex: 1, minWidth: '75px' }}>
                <StyleSelect
                  value={form.makorPb}
                  onChange={e => setForm(prevForm => ({ ...prevForm, makorPb: e.target.value }))}
                >
                  <MenuItem
                    value="RBS"
                    style={{ fontSize: '14px', paddingLeft: '8px', flexBasis: 'auto' }}
                  >
                    RBS
                  </MenuItem>
                  <MenuItem
                    value="HSBC"
                    style={{ fontSize: '14px', paddingLeft: '8px', flexBasis: 'auto' }}
                  >
                    HSBC
                  </MenuItem>
                </StyleSelect>
              </Grid>
              <Grid item style={{ flex: 4, minWidth: '200px' }}>
                <TextFieldStyle
                  value={form?.pbMapping}
                  onChange={e => setForm(prevForm => ({ ...prevForm, pbMapping: e.target.value }))}
                >
                  {form?.pbMapping}
                </TextFieldStyle>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ marginTop: '24px' }}>
              <Typography5Style>Makor Entity</Typography5Style>
            </Grid>

            <Grid xs={12} style={{ display: 'flex', marginTop: '8px', lineHeight: 1 }}>
              <StyleSelect value={entity} onChange={e => setEntity(e.target.value)}>
                <MenuItem value="Makor Securities London" style={{ fontSize: '13px' }}>
                  Makor Securities London
                </MenuItem>
                <MenuItem value="Oscar Gruss" style={{ fontSize: '13px' }}>
                  Oscar Gruss
                </MenuItem>
              </StyleSelect>
            </Grid>

            <Grid item xs={12} style={{ marginTop: '24px' }}>
              <FormControlLabelStyle
                control={
                  <CheckboxEntities
                    checkedIcon={<CheckIcon />}
                    checked={form?.is_structured}
                    onChange={e =>
                      setForm(prevForm => ({ ...prevForm, is_structured: e.target.checked }))
                    }
                  />
                }
                label={<Typography7Style>Structured</Typography7Style>}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: '24px' }}>
              <FormControlLabelStyle
                control={<IOSSwitch checked={giveUp} onChange={e => setGiveUp(e.target.checked)} />}
                label={
                  <Typography
                    style={{
                      color: 'var(--Primary-text, #FFF)',
                      leadingTrim: 'both',
                      textEdge: 'cap',
                      fontFamily: 'Nunito',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: 'normal'
                    }}
                  >
                    Give-up
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid class="luceraValue">
              <Typography5Style>Lucera Drop Value</Typography5Style>
            </Grid>

            <Grid
              item
              style={{
                borderRadius: '4px',
                background: 'var(--Modal-BG, #242428)',
                padding: '12px',
                maxHeight: '300px',
                marginTop: '8px',
                flexDirection: 'column',
                position: 'relative'
              }}
            >
              <LuceraContainer className="container">
                {form.lucera_drop_value.length !== 0 ? (
                  form.lucera_drop_value.map(({ type, lucera }, index) => (
                    <Grid
                      container
                      style={{
                        display: 'flex',
                        marginTop: '8px',
                        gap: '16px'
                      }}
                      key={index}
                    >
                      <Grid
                        item
                        lg={3}
                        md={4}
                        sm={5}
                        xs={5}
                        style={{
                          flex: 1,
                          minWidth: '75px',
                          lineHeight: 1
                        }}
                      >
                        <StyleSelect
                          onChange={event =>
                            handelLuceraDropValue(index, 'lucera_type', event.target.value)
                          }
                          value={type}
                        >
                          <MenuItem
                            value="NY"
                            style={{ fontSize: '14px', paddingLeft: '8px', flexBasis: 'auto' }}
                          >
                            NY
                          </MenuItem>
                          <MenuItem
                            value="LDN"
                            style={{ fontSize: '14px', paddingLeft: '8px', flexBasis: 'auto' }}
                          >
                            LDN
                          </MenuItem>
                        </StyleSelect>
                      </Grid>
                      <Grid item lg={7} md={6} sm={6} xs={6} style={{ flex: 4, minWidth: '200px' }}>
                        <TextFieldStyle
                          fullWidth
                          onChange={event =>
                            handelLuceraDropValue(index, 'lucera_value', event.target.value)
                          }
                          value={lucera}
                        >
                          {lucera}
                        </TextFieldStyle>
                      </Grid>
                      <Grid item xs={1} style={{ flex: 1, minWidth: '24px' }}>
                        <DeleteIconStyle
                          onClick={() => handleDeleteLuceraValue(type, lucera, index)}
                        />
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <Typography5Style>No mapping values set</Typography5Style>
                )}
              </LuceraContainer>

              <Grid item xs={12}>
                <IconButton
                  onClick={addLuceraRows}
                  style={{
                    padding: '0px',
                    position: 'absolute',
                    bottom: '10px', // Adjust as needed
                    right: '10px' // Adjust as needed
                  }}
                >
                  <AddIconStyle />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid style={{ display: 'flex' }}>
              <Typography5Style>Default Booking Recipients</Typography5Style>
            </Grid>
            <Grid item>
              <Grid
                style={{
                  height: '248px',
                  borderRadius: '4px',
                  background: 'var(--Modal-BG, #242428)'
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    height: '210px',
                    maxHeight: '230px',
                    overflowY: 'auto',
                    borderRadius: '5px',
                    '& li:hover': {
                      background: '#babec7'
                    }
                  }}
                  className="container"
                >
                  {form?.emails.length > 0 ? (
                    search.search === undefined || search.search === '' ? (
                      <>
                        {form?.emails.map(email => {
                          return (
                            <ListItem
                              key={email}
                              style={{
                                padding: '11px 12px',
                                borderBottom: '1px solid #39393c',
                                background: 'var(--Modal-BG, #242428)',
                                color: 'var(--Primary-text, #FFF)'
                              }}
                              secondaryAction={
                                <FormControlLabel
                                  control={
                                    form?.emails.includes(email) ? (
                                      <Checked
                                        checked={true}
                                        onClick={() => handleCheckboxChange(email)}
                                      />
                                    ) : (
                                      <UnChecked
                                        checked={false}
                                        onClick={() => handleCheckboxChange(email)}
                                      />
                                    )
                                  }
                                />
                              }
                              disablePadding
                            >
                              <ListItemText primary={email} />
                            </ListItem>
                          )
                        })}
                      </>
                    ) : (
                      emails.map(email => {
                        return (
                          <ListItem
                            key={email}
                            style={{
                              borderBottom: '1.5px solid black',
                              background: 'var(--Modal-BG, #242428)',
                              color: 'var(--Primary-text, #FFF)'
                            }}
                            secondaryAction={
                              <FormControlLabel
                                control={
                                  form?.emails.includes(email) ? (
                                    <Checked
                                      checked={true}
                                      onClick={() => handleCheckboxChange(email)}
                                    />
                                  ) : (
                                    <UnChecked
                                      checked={false}
                                      onClick={() => handleCheckboxChange(email)}
                                    />
                                  )
                                }
                              />
                            }
                            disablePadding
                          >
                            <ListItemText primary={email} />
                          </ListItem>
                        )
                      })
                    )
                  ) : (
                    <Typography5Style style={{ marginTop: '12px', marginLeft: '12px' }}>
                      No emails found
                    </Typography5Style>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ marginTop: '10px' }}>
                <FormControl
                  variant="outlined"
                  style={{
                    width: '100%'
                  }}
                >
                  <OutlinedInput
                    style={{ backgroundColor: '#242428', color: 'white' }}
                    type="email"
                    onChange={handelContractDistribution}
                    placeholder="example@gmail.com"
                    endAdornment={
                      contractDistribution?.data?.length === 0 && (
                        <InputAdornment position="end">
                          <IconButton onClick={submitNewContract} edge="start">
                            <AddIconStyle />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* second grid */}
      <Grid container>
        <Grid item xs={10}>
          <Typography5Style>Product Permission</Typography5Style>
        </Grid>
        <Grid item xs={1.1} style={{ display: 'flex', gap: '5px' }}>
          <Grid>
            <Typography10Style>Total limit</Typography10Style>
          </Grid>
          <Grid style={{ marginTop: '8px' }}>
            <Typography9Style>$1</Typography9Style>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sx={{
          '& li:hover': {
            background: '#babec7'
          }
        }}
        style={{
          background: 'var(--Modal-BG, #242428)',
          overflow: 'auto'
        }}
        className="container"
      >
        <Grid item xs={2} style={{ height: '210px' }}>
          <Grid style={{ display: 'flex', justifyContent: 'space-around' }}>
            {buttonLabels.map((label, index) => {
              return (
                <Grid key={index}>
                  <NavButton
                    isActive={activeButton === label}
                    onClick={() => handleNavButtonClick(label, userType)}
                  >
                    {label}
                  </NavButton>
                </Grid>
              )
            })}
          </Grid>
          <Grid item container xs={10} style={{ height: '170px', padding: '24px' }}>
            <Grid item xs={12}>
              {currencyPermissionData?.map((tenor, index) => {
                return (
                  <React.Fragment key={index}>
                    <StyleInputLabel>{tenor.tenor_name}</StyleInputLabel>
                    <StyleTextField
                      className="container"
                      onChange={e => handleTenorChange(e, tenor)}
                      value={tenor.currencies}
                      sx={{
                        overflow: 'auto',
                        '.MuiInputBase-root.MuiOutlinedInput-root': {
                          fontSize: '12px'
                        },
                        '& li:hover': {
                          background: '#babec7'
                        }
                      }}
                      multiline
                    />
                  </React.Fragment>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <CloseButton onClick={submit}>Close</CloseButton>
        <SaveButton onClick={submitPermission}>Save Changes</SaveButton>
      </Grid>
    </Grid>
  )
}
ClientMmDetails.propTypes = {
  params: PropTypes.object,
  clientMmId: PropTypes.string,
  userType: PropTypes.string
}

export default ClientMmDetails
