import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { Label } from '../../../../../styled/global.style'
import { TextFieldStyle } from '../../../../../../pages/TradeBlotter/components/FilterTable/FilterTable.style'
import { FormError } from '../../../../ModalApp.style'

const EnhancedReverseOptionFirstColumn = ({ form, handelDateChange, disabled = false, errors }) => {
  return (
    <>
      <Box width={'100%'} >
        <Label>Window Start *</Label>
        <TextFieldStyle
          type="date"
          variant="outlined"
          value={form.window_start}
          myerror={String(!!errors.window_start)}
          disabled={disabled}
          onChange={event => handelDateChange('window_start', event.target.value)}
        />
        {errors.window_start && <FormError>{errors.window_start}</FormError>}
      </Box>
    </>
  )
}

export default EnhancedReverseOptionFirstColumn

EnhancedReverseOptionFirstColumn.propTypes = {
  disabled: PropTypes.bool,
  handelFormChange: PropTypes.func,
  handelDateChange: PropTypes.func,
  form: PropTypes.object,
  errors: PropTypes.object
}
