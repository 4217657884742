import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PriceContainer, TextPriceStyle } from '../TradeBlotter.style'
import PriceIcon from '../components/PriceIcon/PriceIcon'
import { Box, Typography } from '@mui/material'
import CurrencyLogo from '../../../components/CurrencyLogo/CurrencyLogo'
import FlagCurrency from '../../../components/FlagCurrency/FlagCurrency'

const useAgGridPriceSettings = ({
  setOpenPrice,
  getLayoutFromLS,
  openPrice,
  setLayouts,
  defaultLayout
}) => {
  useEffect(() => {
    const storage = getLayoutFromLS()
    if (
      storage.lg.length === 2 &&
      storage.md.length === 2 &&
      storage.sm.length === 2 &&
      storage.sm.length === 2
    ) {
      setOpenPrice(true)
    } else setOpenPrice(false)
  }, [])

  const PriceComponent = prop => {
    return (
      <PriceContainer>
        <TextPriceStyle status={prop.data.status}>{prop.data.LAST_PRICE} </TextPriceStyle>
        <PriceIcon status={prop.data.status} />
      </PriceContainer>
    )
  }
  const CurrencyPairCell = prop => {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: "fixed",
          gap: '8px',
          width: '100%',
          height: '100%',
          minWidth: '150px',
          maxWidth: '150px'
        }}
      >
        <Typography>{prop.data.base_currency}</Typography>
        <CurrencyLogo currencyCode={prop.data.base_currency} />
        <FlagCurrency countryCode={prop.data.base_currency_country} />
        <Typography>/</Typography>
        <Typography>{prop.data.counter_currency}</Typography>
        <CurrencyLogo currencyCode={prop.data.counter_currency} />
        <FlagCurrency countryCode={prop.data.counter_currency_country} />
      </Box>
    )
  }

  const defaultColDef2 = { flex: 1 }

  const columnsDefs2 = [
    {
      headerName: 'Product',
      cellRenderer: CurrencyPairCell
    },
    {
      headerName: 'Price',
      cellRenderer: PriceComponent
    }
  ]

  const getRowId2 = useCallback(params => {
    return params.data.order_id
  })

  // const handleClick = () => {
  //   setOpenPrice(!openPrice)
  //   let layoutFromStorage
  //   if (openPrice) {
  //     layoutFromStorage = getLayoutFromLS()
  //     layoutFromStorage.lg[0].w = 12
  //   } else {
  //     layoutFromStorage = defaultLayout
  //   }

  //   setLayouts(layoutFromStorage)
  // }

  // return { handleClick, defaultColDef2, columnsDefs2, getRowId2 }
  return { defaultColDef2, columnsDefs2, getRowId2 }
}

useAgGridPriceSettings.propTypes = {
  setOpenPrice: PropTypes.func,
  getLayoutFromLS: PropTypes.func,
  openPrice: PropTypes.bool,
  setLayouts: PropTypes.func,
  defaultLayout: PropTypes.object
}

export default useAgGridPriceSettings
