import React from 'react'
import PropTypes from 'prop-types'
import { PageNextIcon, PageNumberText, PagePrevIcon, PageText } from './Pagination.style'
import { Box, IconButton } from '@mui/material'

const Pagination = ({
  handelNextPagination,
  handelPrevPagination,
  page,
  disabledPrev,
  disabledNext
}) => {
  return (
    <Box
      style={{
        backgroundColor: '#242428',
        width: '100%',
        paddingTop: '10px',
        paddingBottom: '10px',
        display: 'flex',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight:"1.5px"
      }}
    >
      <IconButton onClick={handelPrevPagination} disabled={disabledPrev}>
        <PagePrevIcon />
      </IconButton>
      <PageText>Page</PageText>
      <PageNumberText> {page}</PageNumberText>
      <IconButton onClick={handelNextPagination} disabled={disabledNext}>
        <PageNextIcon />
      </IconButton>
    </Box>
  )
}

Pagination.propTypes = {
  handelNextPagination: PropTypes.func,
  handelPrevPagination: PropTypes.func,
  page: PropTypes.number,
  disabledPrev: PropTypes.bool,
  disabledNext: PropTypes.bool
}

export default Pagination
