import React from 'react'
import PropTypes from 'prop-types'
import { InputBaseStyle } from '../../../../ModalApp.style'
import { Box } from '@mui/material'
import { Label } from '../../../../../styled/global.style'

const DoubleNoTouchOptionThirdColumn = ({ form, handelFormChange, disabled = false }) => {
  return (
    <>
      <Box width={'100%'}>
        <Label>Spot Ref *</Label>
        <InputBaseStyle
          name="spot_ref"
          placeholder="0.00"
          value={form.spot_ref}
          onChange={handelFormChange}
          disabled={disabled}
        />
      </Box>
      <Box width={'100%'}>
        <Label>Lower Barrier *</Label>
        <InputBaseStyle
          name="lower_barrier"
          placeholder="0.00"
          disabled={disabled}
          value={form.lower_barrier}
          onChange={handelFormChange}
        />
      </Box>
      <Box width={'100%'}>
        <Label>Upper Barrier *</Label>
        <InputBaseStyle
          name="upper_barrier"
          placeholder="0.00"
          value={form.upper_barrier}
          onChange={handelFormChange}
          disabled={disabled}
        />
      </Box>
      
    </>
  )
}

export default DoubleNoTouchOptionThirdColumn

DoubleNoTouchOptionThirdColumn.propTypes = {
  handelFormChange: PropTypes.func,
  disabled: PropTypes.bool,
  form: PropTypes.object
}
