import { Box, CircularProgress, Divider, Stack, styled, Typography } from '@mui/material'

export const TitleReportStyle = styled(Typography)(({ theme }) => ({
  color: theme.palette.modal_report_title.primary,
}))

export const ContainerDealReportSection = styled(Box)(() => ({
  padding: '24px'
}))

export const ContainerDealReportSectionRow = styled(Box)(() => ({
  paddingLeft:"8px",
  display: 'flex',
  width:"252px",
  maxWidth:"252px",
  marginBottom:"5px",
  alignItems: 'center',
  justifyContent: 'space-between'
}))

export const ContainerDealReport = styled(Stack)(({ theme }) => ({
  minWidth: '300px',
  maxWidth: '300px',
  maxHeight: '466px',
  width: '280px',
  overflowY: 'auto',
  [theme.breakpoints.down('md')]: {
    height: '100dvh',
    width: '100dvw',
    maxHeight: '100dvh'
  }
}))

export const DividerDealReport = styled(Divider)(({ theme }) => ({
  border: `1px solid ${theme.palette.separators.primary}`
}))

export const TypographyDateStyle = styled(Typography)(({ theme }) => ({
  fontSize: '10px',
  fontStyle: 'italic',
  color: `${theme.palette.button.disable} !important`
}))

export const TypographyPrimeBrokerStyle = styled(Typography)(({ theme }) => ({
  borderRadius: '2px',
  background: `${theme.palette.button.cancel.primary}`,
  color: `${theme.palette.tabs.backgroundColor.primary} !important`,
  textAlign: 'center',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Nunito',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  display: 'inline-flex',
  height: '12px !important',
  padding: "3px 8px !important",
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  flexShrink: 0
}))

export const SpinnerDealReportLoader = styled(CircularProgress)(({ theme }) => ({
  height: '16px !important',
  width: '16px !important',
  color: theme.palette.button.text.primary
}))
