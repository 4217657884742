import React from 'react'
import { toolBarTabs } from '../../data'
import { StaticsContainer, StaticsItem, TabsContainerStyle, TabStyle } from './StaticsToolBar.style'
import PropTypes from 'prop-types'
import SearchTextField from '../../../../components/SearchTextField/SearchTextField'
import { ButtonAddNew } from '../../../../components/styled/global.style'

const StaticsToolBar = ({ tab, setTab, handelSearch, handelNewModal, searchValue }) => {
  const handleChange = event => {
    localStorage.setItem('tab', event.target.name)
    setTab(event.target.name)
  }
  return (
    <StaticsContainer>
      <TabsContainerStyle
        value={tab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {toolBarTabs.map((tab, index) => (
          <TabStyle key={tab} name={tab} label={tab} value={tab} index={index} />
        ))}
      </TabsContainerStyle>
      <StaticsItem>
        <SearchTextField handleChange={handelSearch} state={searchValue} />
        <ButtonAddNew style={{ marginRight: "16px" }} onClick={handelNewModal}> Add New</ButtonAddNew>
      </StaticsItem>
    </StaticsContainer>
  )
}

StaticsToolBar.propTypes = {
  tab: PropTypes.string,
  searchValue: PropTypes.string,
  setTab: PropTypes.any,
  handelSearch: PropTypes.func,
  handelNewModal: PropTypes.func
}

export default StaticsToolBar
