/* eslint-disable */
import React from 'react';
import DoughnutChartChartMmClientData from './DoughnutChartChartMmClientData';
import NightingaleChartChartMmClientData from "./NightingaleChartMmClientData"
import { Grid } from '@mui/material';

const ChartRow = () => {
  const chartData1 = [
    { value: 1048},
    { value: 735 },
  ];

  const chartData2 = [
    { value: 735 },
    { value: 1048},
    
  ];
  const chartData3 = [
    { value: 30 },
    { value: 30},
    { value: 40},
  ];
  return (
    <Grid container>
      <Grid item xs={3}>
        <NightingaleChartChartMmClientData title="Referral Channels" data={chartData3} />
        <Grid 
        style={{color:"var(--Primary-text, #FFF)",fontSize:"16px",marginTop:"-66px",textAlign:"center"}}
        >Monthly Volume /Trading Account
        </Grid>
      </Grid>
      <Grid item xs={3}> 
        <DoughnutChartChartMmClientData title="Website Traffic Sources" data={chartData1} />
        <Grid 
        style={{color:"var(--Primary-text, #FFF)",fontSize:"16px",marginTop:"-66px",textAlign:"center"}}
        >Avg Daily P&L /Trading Account</Grid>
      </Grid>
      <Grid item xs={3}>
        <DoughnutChartChartMmClientData title="Referral Channels" data={chartData2} />
        <Grid style={{color:"var(--Primary-text, #FFF)",fontSize:"16px",marginTop:"-66px",textAlign:"center"}}
        >Avg Size /Trading Account</Grid>
      </Grid>
   
    </Grid>
  );
};

export default ChartRow;
