import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import React from 'react'
import PropTypes from 'prop-types'
import './Table.css'
import 'ag-grid-enterprise'

const Table = ({ rows, columns }) => {
  return (
    <div
      style={{ height: 'calc(100vh - 200px)', width: '100%'}}
      className="ag-theme-quartz-dark"
    >
      <AgGridReact rowData={rows} columnDefs={columns} />
    </div>
  )
}

Table.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  actionsComponent: PropTypes.any
}

export default Table
