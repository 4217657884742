import React from 'react'
import PropTypes from 'prop-types'
import PaperAutoComplete from '../../../../../PaperAutoComplete/PaperAutoComplete'
import {
  AutocompleteStyle,
  FormError,
  InputBaseStyle,
  TextFieldModalStyle
} from '../../../../ModalApp.style'
import { Box } from '@mui/material'
import { Label } from '../../../../../styled/global.style'
import SelectWithArrow from '../../../../../SelectWithArrow/SelectWithArrow'
import { TextFieldStyle } from '../../../../../../pages/TradeBlotter/components/FilterTable/FilterTable.style'

const VanillaDealFirstColumnClientSide = ({
  clients,
  form,
  handelFormChange,
  handelDateChange,
  handelFocusCurrencyPair,
  handelBlurCurrencyPair,
  children,
  disabledField,
  errors
}) => {
  return (
    <>
      <Box width={'100%'}>
        <Label>Client *</Label>
        {Array.isArray(clients.data) && (
          <AutocompleteStyle
            onChange={(event, element) => {
              event.target.name = 'client'
              element = element ?? ''
              handelFormChange(event, element)
            }}
            size={'small'}
            value={form.client}
            options={clients.data.map(({ name }) => name)}
            renderInput={params => (
              <TextFieldModalStyle fullWidth {...params} placeholder={'Select Client'} />
            )}
            ListboxComponent={PaperAutoComplete}
          />
        )}
      </Box>

      <Box width={'100%'} >
        <Label>Currency Pair *</Label>
        <InputBaseStyle
          value={form.currency_pair}
          name="currency_pair"
          onChange={handelFormChange}
          onFocus={handelFocusCurrencyPair}
          onBlur={handelBlurCurrencyPair}
          error={errors.currency_pair}
        />
        {errors.currency_pair !== '' && <FormError>{errors.currency_pair}</FormError>}
      </Box>

      <Box width={'100%'}>
        <Label>Strike Price*</Label>
        <InputBaseStyle
          value={form.strike}
          name="strike"
          disabled={disabledField.strike}
          onChange={handelFormChange}
          placeholder="0.00"
        />
      </Box>
      <Box width={'100%'}>
        <Label>Settlement Date *</Label>
        <TextFieldStyle
          type="date"
          variant="outlined"
          value={form.settlement_date}
          onChange={event => handelDateChange('settlement_date', event.target.value)}
        />
      </Box>
      <Box width={'100%'}>
        <Label>Premium Amount*</Label>
        <InputBaseStyle
          value={form.client_premium_amount}
          name="client_premium_amount"
          onChange={handelFormChange}
          placeholder="0.00"
        />
      </Box>
      <Box width={'100%'}>
        <Label>Exercise Style *</Label>
        <SelectWithArrow
          name="option_exercise_style"
          placeholder="Select Exercise Style"
          orderType={form.option_exercise_style}
          onChange={handelFormChange}
          items={[{ name: 'European' }, { name: 'American' }]}
        />
      </Box>
      {children}
    </>
  )
}

export default VanillaDealFirstColumnClientSide

VanillaDealFirstColumnClientSide.propTypes = {
  clients: PropTypes.object,
  handelFormChange: PropTypes.func,
  handelDateChange: PropTypes.func,
  handelFocusCurrencyPair: PropTypes.func,
  handelBlurCurrencyPair: PropTypes.func,
  form: PropTypes.object,
  disabledField: PropTypes.object,
  errors: PropTypes.object,
  children: PropTypes.node
}
