import dayjs from 'dayjs'
import { changeTimeLocalToUtc } from '../../../../../helper'
import { DATE_FORMAT } from '../../../../../utils/constants'

export class DealDigitalKnockInKnockOut {
  constructor(form) {
    this.deal_type = form.deal_type.name
    this.client_side = form.client_side
    this.base_currency = form.currency_pair.substring(0,3)
    this.counter_currency = form.currency_pair.substring(3,6)
    this.client = form.client
    this.market_maker = form.market_maker
    this.trade_date = changeTimeLocalToUtc(form.trade_date, DATE_FORMAT.REGULAR_DATE_TIME)
    this.quantity = form.quantity
    this.client_premium_amount = form.client_premium_amount
    this.market_maker_premium_amount = form.market_maker_premium_amount
    this.client_premium_currency = form.client_premium_currency.name
    this.market_maker_premium_currency = form.market_maker_premium_currency.name
    this.fixing_source = form.fixing_source
    this.fixing_date = dayjs(form.fixing_date).format(DATE_FORMAT.REGULAR_DATE)
    this.client_premium_date = dayjs(form.client_premium_date).format(DATE_FORMAT.REGULAR_DATE)
    this.market_maker_premium_date = dayjs(form.market_maker_premium_date).format(
      DATE_FORMAT.REGULAR_DATE
    )
    this.put_call = form.put_call
    this.settlement_date = dayjs(form.settlement_date).format(DATE_FORMAT.REGULAR_DATE)
    this.expiry = dayjs(form.expiry).format(DATE_FORMAT.REGULAR_DATE)
    this.strike = form.strike
    this.cut = form.cut
    this.client_option_payment_currency = form.client_option_payment_currency.name
    this.market_maker_option_payment_currency = form.market_maker_option_payment_currency.name
    this.option_exercise_style = form.option_exercise_style
    this.client_trader_name = form.client_trader_name
    this.market_maker_trader_name = form.market_maker_trader_name
    this.payout_amount = form.payout_amount
    this.payout_currency = form.payout_currency.name
    this.spot_ref = form.spot_ref
    this.window_start = dayjs(form.window_start).format(DATE_FORMAT.REGULAR_DATE)
    this.window_end = dayjs(form.window_end).format(DATE_FORMAT.REGULAR_DATE)
    this.barrier = form.barrier
    this.timing = form.timing
    this.trade_origin = 'FXER'
  }
}
