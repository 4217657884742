import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { Label } from '../../../../../styled/global.style'
import { TextFieldStyle } from '../../../../../../pages/TradeBlotter/components/FilterTable/FilterTable.style'

const RemainingOptionsTradeSecondColumn = ({ form, handelDateChange, disabled = false }) => {
  return (
    <>
      <Box width={'100%'}>
        <Label>Window End *</Label>
        <TextFieldStyle
          type="date"
          variant="outlined"
          value={form.window_end}
          disabled={disabled}
          onChange={event => handelDateChange('window_end', event.target.value)}
        />
      </Box>
    </>
  )
}

export default RemainingOptionsTradeSecondColumn

RemainingOptionsTradeSecondColumn.propTypes = {
  disabled: PropTypes.bool,
  handelDateChange: PropTypes.func,
  form: PropTypes.object
}
