import React from 'react'
import PropTypes from 'prop-types'
import { InputBaseStyle } from '../../../../ModalApp.style'
import { Box } from '@mui/material'
import { Label } from '../../../../../styled/global.style'

const RemainingOptionsTradeThirdColumn = ({ form, handelFormChange, disabled = false }) => {
  return (
    <>
      <Box width={'100%'}>
        <Label>Barrier *</Label>
        <InputBaseStyle
          value={form.barrier}
          name="barrier"
          disabled={disabled}
          onChange={handelFormChange}
          placeholder="0.00"
        />
      </Box>
      <Box width={'100%'}>
        <Label>Spot Ref *</Label>
        <InputBaseStyle
          name="spot_ref"
          placeholder="0.00"
          value={form.spot_ref}
          disabled={disabled}
          onChange={handelFormChange}
        />
      </Box>
    </>
  )
}

export default RemainingOptionsTradeThirdColumn

RemainingOptionsTradeThirdColumn.propTypes = {
  disabled: PropTypes.bool,
  handelFormChange: PropTypes.func,
  form: PropTypes.object
}
