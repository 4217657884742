import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { MODAL_TITLE, MODAL_TYPE, routes } from '../../../../utils/constants.js'
import useAxios from '../../../../hooks/useAxios.js'
import { setModal } from '../../../../redux/slices/Modal/modalSlice.js'
import axios from 'axios'
import { EditIconStyle } from '../../../../components/styled/global.style.js'
import Table from '../../../../components/Table/Table.js'
import Loader from '../../../../components/Loader/Loader.js'
import { handelNotifications } from '../../../../redux/slices/Notification/notificationSlice.js'

const PrimeBroker = ({ params }) => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)

  const request = {
    endPoint: routes.primeBrokerMapping,
    params,
    modalType: MODAL_TYPE.PRIME_BROKER
  }
  const { response, loading } = useAxios(request)

  const editPrimeBroker = async id => {
    const url = process.env.REACT_APP_SERVER_URL.concat(routes.primeBrokerMapping, '/', id)
    try {
      const result = await axios.get(url, { headers: { Authorization: token } })
    
      dispatch(
        setModal({
          type: MODAL_TYPE.PRIME_BROKER,
          title: MODAL_TITLE.EDIT_PRIME_BROKER,
          data: result.data,
          isOpen: true
        })
      )
    } catch (error) {
      dispatch(
        handelNotifications({
          id: Date.now(),
          type: 'error',
          message: 'Sorry Something Went Wrong'
        })
      )
    }
  }

  const EditClient = prop => {
    return <EditIconStyle onClick={() => editPrimeBroker(prop.data.id)} />
  }

  const columns = [
    {
      headerName: 'Prime Broker Name',
      field: 'name'
    },
    {
      headerName: 'Group Email',
      field: 'group_email'
    },
    {
      flex: 1,
      resizable: false,
      suppressMovable: true,
      cellClass: 'suppress-movable-col'
    },
    { headerName: 'Edit', cellRenderer: EditClient }
  ]
  if (loading === true) {
    return <Loader />
  }

  if (response.status === 200) {
    return <Table rows={response.data} columns={columns} />
  }
}

PrimeBroker.propTypes = { setParams: PropTypes.func, params: PropTypes.string }

export default PrimeBroker
