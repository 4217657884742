import { styled } from '@mui/system'
import { Grid, Radio, Stack, TextField } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

export const RadioStyle = styled(Radio)(({ theme }) => ({
  color: `${theme.palette.button.save.primary} !important`,
  '&:checked': {
    color: theme.palette.button.save.primary,
    backgroundColor: theme.palette.button.save.primary
  }
}))

export const StackContainer = styled(Stack)(({ theme }) => ({
  gap: '24px',
  width: '100%'
}))

export const StackGrey = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.separators.primary,
  borderRadius: '4px',
  padding: '10px'
}))

export const AddIconStyle = styled(AddIcon)(({ theme }) => ({
  width: '32px',
  height: '24px',
  margin: '5px',
  borderRadius: '4px',
  backgroundColor: theme.palette.button.edit.primary
}))

export const TextFieldDisabled = styled(TextField)(({ theme }) => ({
  color: theme.palette.text.primary,
  '& .MuiInputBase-root.Mui-disabled': {
    color: `${theme.palette.text.primary} !important` // This targets the input container
  },
  '& .MuiInputBase-input.Mui-disabled': {
    color: `${theme.palette.text.primary} !important` // This targets the input element itself
  },
  '& .MuiInputLabel-root.Mui-disabled': {
    color: `${theme.palette.text.primary} !important` // This targets the label when the field is disabled
  }
}))

export const LuceraDropValueContainer = styled(Grid)(({ theme }) => ({
  // display: 'flex',
  // gap: '8px'
}))
