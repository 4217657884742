import React, { useEffect, useState } from 'react'
import { HeaderWarper, StyleLogoutIcon, StyleMenuItem, Title, UserIcon } from './Header.style'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  IconButton,
  Menu,
  Typography,
  List,
  Modal,
  ListItem,
  ListItemText,
  Checkbox
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { userLogout } from '../../redux/slices/Auth/AuthSlice'
import DailyPnl from './components/DailyPnl/DailyPnl'
import { ReactComponent as MenuIcon } from '../../assets/Frame 25.svg'
import { routes } from '../../utils/constants'
import { setWidgets } from '../../redux/slices/widgets/WidgetsSlice'
import axios from 'axios'

const Header = () => {
  const token = useSelector(state => state.auth.token)
  const title = useSelector(state => state.auth.title)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const items = ['Trade Blotter', 'Rates']
  const [menuOpen, setMenuOpen] = useState(false)
  const [checkedItems, setCheckedItems] = useState([])
  const widgets = useSelector(state => state.widgets.widgets)

  const handelClick = () => {
    setOpen(!open)
  }

  const handelLogout = () => {
    dispatch(userLogout(navigate))
  }

  const handelMenuClick = () => {
    setMenuOpen(true)
  }

  const handleToggle = value => () => {
    const currentIndex = checkedItems.indexOf(value)
    const newChecked = [...checkedItems]
    if (currentIndex === -1) {
      addWidgetToUserPreference(value)
      newChecked.push(value)
    } else {
      removeWidgetToUserPreference(value)
      newChecked.splice(currentIndex, 1)
    }

    setCheckedItems(newChecked)
  }
  const handleClose = () => {
    setMenuOpen(false)
  }

  const addWidgetToUserPreference = async val => {
    try {
      const url = process.env.REACT_APP_SERVER_URL.concat(routes.addWidget, '/')

      const result = await axios.post(url, { val }, { headers: { Authorization: token } })
      dispatch(setWidgets(result.data))
    } catch (error) {
      console.log(error)
    }
  }

  const removeWidgetToUserPreference = async val => {
    try {
      const url = process.env.REACT_APP_SERVER_URL.concat(routes.removeWidget, '/')
      const result = await axios.post(url, { val }, { headers: { Authorization: token } })
      dispatch(setWidgets(result.data))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (widgets && widgets.length > 0) {
      const widgetPreferences = widgets.map(widget => widget.preferences)
      setCheckedItems(widgetPreferences)
    } else {
      setCheckedItems([])
    }
  }, [widgets])

  return (
    <HeaderWarper id="header">
      <Title>{title}</Title>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <Box
          style={{
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%'
          }}
        >
          <IconButton
            onClick={handelMenuClick}
            size="small"
            sx={{ ml: 2 }}
            style={{ borderRadius: '4px' }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MenuIcon />
          </IconButton>
          <Modal open={menuOpen} onClose={handleClose}>
            <Box
              sx={{
                position: 'absolute',
                top: '120px',
                right: '70px',
                transform: 'translate(-50%, -50%)',
                flexShrink: '0',
                borderRadius: '8px',
                background: '#242428'
              }}
            >
              <List style={{ padding: '12px 8px', background: '#242428', borderRadius: '8px' }}>
                {items.map(item => (
                  <ListItem
                    sx={{ margin: '1px !important' }}
                    key={item}
                    button
                    onClick={handleToggle(item)}
                  >
                    <Checkbox
                      sx={{
                        width: '16px',
                        height: '16px',
                        '&.Mui-checked': {
                          color: '#A4A4FF'
                        },
                        marginRight: '4px'
                      }}
                      checked={checkedItems.indexOf(item) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                    <ListItemText
                      primary={item}
                      primaryTypographyProps={{
                        sx: {
                          color: '#FFF',
                          fontFamily: 'Nunito',
                          fontSize: '12px !important',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          marginLeft: '8px',
                          marginRight: '32px',
                          lineHeight: 'normal',
                          leadingTrim: 'both',
                          textEdge: 'cap'
                        }
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Modal>
          <DailyPnl />
          <IconButton
            onClick={handelClick}
            size="small"
            sx={{ ml: 0 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <UserIcon />
          </IconButton>
          <Menu
            open={open}
            onClose={handelClick}
            onClick={handelClick}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  borderRadius: '4px !important',
                  backgroundColor: '#39393C',
                  marginTop: '40px',
                  top: '20px !important',
                  padding: '0px',
                  '&::before': {
                    display: 'block',
                    position: 'absolute'
                  },
                  '& .MuiList-root': {
                    borderRadius: '4px !important',
                    padding: '8px 24px'
                  }
                }
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            <StyleMenuItem onClick={handelLogout}>
              <StyleLogoutIcon />
              <Typography style={{ color: 'white', marginLeft: '12px', fontSize: '14px' }}>
                Log out
              </Typography>
            </StyleMenuItem>
          </Menu>
        </Box>
      </Box>
    </HeaderWarper>
  )
}

export default Header
