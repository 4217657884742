import React from 'react'
import PropTypes from 'prop-types'
import { AutocompleteStyle, TextFieldModalStyle } from '../../../../ModalApp.style'
import { Box, Typography } from '@mui/material'
import { Label } from '../../../../../styled/global.style'
import { OptionContainer } from '../../Trade.stye'
import FlagCurrency from '../../../../../FlagCurrency/FlagCurrency'
import CurrencyLogo from '../../../../../CurrencyLogo/CurrencyLogo'
import PaperAutoComplete from '../../../../../PaperAutoComplete/PaperAutoComplete'

const DigitalOptionTradeSecondColumn = ({
  form,
  currencyData,
  currencyInitial,
  handelFormChange,
  disabled = false
}) => {
  return (
    <>
      <Box width={'100%'}>
        <Label>Payout Currency*</Label>
        {Array.isArray(currencyData.data) && (
          <AutocompleteStyle
            onChange={(event, element) => {
              event.target.name = 'payout_currency'
              element = element ?? currencyInitial
              handelFormChange(event, element)
            }}
            size={'small'}
            value={form.payout_currency}
            getOptionLabel={option => option.name}
            options={currencyData.data}
            renderInput={params => (
              <TextFieldModalStyle
                {...params}
                fullWidth
                placeholder="Select Currency"
                disabled={disabled}
              />
            )}
            disabled={disabled}
            renderOption={(props, option) => (
              <OptionContainer {...props} key={option.id}>
                <FlagCurrency countryCode={option.code3} />
                <CurrencyLogo currencyCode={option.name} />
                <Typography style={{ marginLeft: 'auto' }}>{option.name} </Typography>
              </OptionContainer>
            )}
            ListboxComponent={PaperAutoComplete}
          />
        )}
      </Box>
    </>
  )
}

export default DigitalOptionTradeSecondColumn

DigitalOptionTradeSecondColumn.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object,
  currencyData: PropTypes.object,
  currencyInitial: PropTypes.object,
  handelFormChange: PropTypes.func
}
