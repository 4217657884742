import { styled } from '@mui/system'
import { Typography } from '@mui/material'

export const ButtonType = styled(Typography)(() => {
  return {
    color: ' var(--Secondary-text, #707079)',
    textEdge: 'cap',
    fontFamily: 'Nunito',
    fontSize: '14px',
    padding: '0px',
    cursor: 'pointer',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    textDecorationLine: 'underline',
    backgroundColor: 'transparent',
    border: 'none'
  }
})

export const TypographyStyle = styled(Typography)(() => {
  return {
    color: 'var(--Primary-text, #FFF)',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    paddingTop: '7px'
  }
})
