import React from 'react'
import PropTypes from 'prop-types'
import {
  AutocompleteStyle,
  InputBaseStyle,
  SubContainer,
  TextFieldModalStyle
} from '../../../../ModalApp.style'
import { Box } from '@mui/material'
import PaperAutoComplete from '../../../../../PaperAutoComplete/PaperAutoComplete'
import { Label } from '../../../../../styled/global.style'
import { TextFieldStyle } from '../../../../../../pages/TradeBlotter/components/FilterTable/FilterTable.style'
import { ButtonGroupStyle, PutCallButton } from '../../Deal.style'

const VanillaDealThirdColumnClientSide = ({
  form,
  handelFormChange,
  handelDateChange,
  putCall,
  cut,
  fixingSource,
  children,
  disabledField
}) => {
  return (
    <SubContainer item width={'225px'}>
      <Box width={'100%'}>
        <Label>Trade Date *</Label>
        <TextFieldStyle type="datetime-local" value={form.trade_date} disabled />
      </Box>
      <Box width={'100%'}>
        <Label>Put/Call *</Label>
        {Array.isArray(putCall) && (
          <ButtonGroupStyle variant="outlined">
            {putCall.map(({ name }, id) => (
              <PutCallButton
                name="put_call"
                key={id}
                value={name}
                disabled={disabledField.put_call}
                select={String(form.put_call === name)}
                onClick={handelFormChange}
              >
                {name}
              </PutCallButton>
            ))}
          </ButtonGroupStyle>
        )}
      </Box>
      <Box width={'100%'}>
        <Label>Quantity*</Label>
        <InputBaseStyle
          value={form.quantity}
          name="quantity"
          onChange={handelFormChange}
          placeholder="0.00"
        />
      </Box>
      <Box width={'100%'}>
        <Label>Cut *</Label>
        {Array.isArray(cut.data) && (
          <AutocompleteStyle
            onChange={(event, element) => {
              event.target.name = 'cut'
              element = element ?? ''
              handelFormChange(event, element)
            }}
            size={'small'}
            value={form.cut}
            options={cut.data.map(({ cut }) => cut)}
            renderInput={params => (
              <TextFieldModalStyle fullWidth {...params} placeholder="Select Cut" />
            )}
            ListboxComponent={PaperAutoComplete}
          />
        )}
      </Box>
      <Box width={'100%'}>
        <Label>Fixing Source *</Label>
        {Array.isArray(fixingSource.data) && (
          <AutocompleteStyle
            onChange={(event, element) => {
              event.target.name = 'fixing_source'
              element = element ?? ''
              handelFormChange(event, element)
            }}
            disabled={disabledField.fixing_source}
            size={'small'}
            value={form.fixing_source}
            options={fixingSource.data.map(({ name }) => name)}
            renderInput={params => (
              <TextFieldModalStyle
                fullWidth
                {...params}
                placeholder="Select Fix Source"
                disabled={disabledField.fixing_source}
              />
            )}
            ListboxComponent={PaperAutoComplete}
          />
        )}
      </Box>

      <Box width={'100%'}>
        <Label>Premium Date *</Label>
        <TextFieldStyle
          type="date"
          variant="outlined"
          value={form.client_premium_date}
          onChange={event => handelDateChange('client_premium_date', event.target.value)}
        />
      </Box>
      
      {children}
    </SubContainer>
  )
}

export default VanillaDealThirdColumnClientSide

VanillaDealThirdColumnClientSide.propTypes = {
  fixingSource: PropTypes.object,
  putCall: PropTypes.array,
  cut: PropTypes.object,
  handelFormChange: PropTypes.func,
  handelDateChange: PropTypes.func,
  form: PropTypes.object,
  disabledField: PropTypes.object,
  children: PropTypes.node
}
