import { ReactComponent as PrevIcon } from '../../assets/prev-page.svg'
import { ReactComponent as NextIcon } from '../../assets/next-page.svg'
import { styled, Typography } from '@mui/material'

export const PagePrevIcon = styled(PrevIcon)(({ theme }) => ({
  color: theme.palette.svg.clearIcon,
  fontSize: '30px',
  '& .Mui-disabled': {
    color: theme.palette.button.disable
  }
}))

export const PageNextIcon = styled(NextIcon)(({ theme }) => ({
  color: theme.palette.svg.clearIcon,
  fontSize: '30px',
  '& .Mui-disabled': {
    color: theme.palette.button.disable
  }
}))

export const PageNumberText = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.svg.clearIcon,
    fontFamily: 'Nunito',
    fontWeight: 500,
    fontSize: '14px',
    marginLeft: '8px',
    marginRight: '24px'
  }
})

export const PageText = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.button.disable,
    fontFamily: 'Nunito',
    fontWeight: 500,
    fontSize: '14px',
    marginLeft: '24px'
  }
})
