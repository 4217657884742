import React from 'react'
import { DealSpot } from '../classes/DealSpot'
import { ClientDealVanillaForm } from '../classes/ClientDealVanillaForm'
import { DealVanilla } from '../classes/DealVanilla'
import { ClientDealDigitalOptionForm } from '../classes/ClientDealDigitalOptionForm'
import { DealDigitalOption } from '../classes/DealDigitalOption'
import { ClientDealDigitalKnockInKnockOutForm } from '../classes/ClientDealDigitalKnockInKnockOutForm'
import { DealDigitalKnockInKnockOut } from '../classes/DealDigitalKnockInKnockOut'
import { ClientDealDigitalDoublesForm } from '../classes/ClientDealDigitalDoublesForm'
import { DealDigitalDoubles } from '../classes/DealDigitalDoubles'
import { ClientDealDoubleNoTouchOptionForm } from '../classes/ClientDealDoubleNoTouchOptionForm'
import { DealDoubleNoTouchOption } from '../classes/DealDoubleNoTouchOption'
import { ClientDealMoreDoublesForm } from '../classes/ClientDealMoreDoublesForm'
import { DealMoreDoubles } from '../classes/DealMoreDoubles'
import { ClientDealTouchOptionsForm } from '../classes/ClientDealTouchOptionsForm'
import { DealTouchOptions } from '../classes/DealTouchOptions'
import { ClientDealEnhancedReverseOptionsForm } from '../classes/ClientDealEnhancedReverseOptionsForm'
import { DealEnhancedReverseOptions } from '../classes/DealEnhancedReverseOptions'
import { ClientDealRemainingOptionsForm } from '../classes/ClientDealRemainingOptionsForm'
import { DealRemainingOptions } from '../classes/DealRemainingOptions'
import { webSocketWorkerEvent } from '../../../../../services'
import dayjs from 'dayjs'
import { DATE_FORMAT, DEAL_GROUP } from '../../../../../utils/constants'
import DigitalOptionTradeFirstColumn from '../../Trade/components/DigitalOption/DigitalOptionTradeFirstColumn'
import DigitalOptionTradeSecondColumn from '../../Trade/components/DigitalOption/DigitalOptionTradeSecondColumn'
import DigitalOptionTradeThirdColumn from '../../Trade/components/DigitalOption/DigitalOptionTradeThirdColumn'
import DigitalKnockInKnockOutTradeFirstColumn from '../../Trade/components/DigitalKnockInKnockOut/DigitalKnockInKnockOutTradeFirstColumn'
import DigitalKnockInKnockOutTradeSecondColumn from '../../Trade/components/DigitalKnockInKnockOut/DigitalKnockInKnockOutTradeSecondColumn'
import DigitalKnockInKnockOutTradeThirdColumn from '../../Trade/components/DigitalKnockInKnockOut/DigitalKnockInKnockOutTradeThirdColumn'
import DoubleNoTouchOptionFirstColumn from '../../Trade/components/DoubleNoTouchOption/DoubleNoTouchOptionFirstColumn'
import DoubleNoTouchOptionSecondColumn from '../../Trade/components/DoubleNoTouchOption/DoubleNoTouchOptionSecondColumn'
import DoubleNoTouchOptionThirdColumn from '../../Trade/components/DoubleNoTouchOption/DoubleNoTouchOptionThirdColumn'
import DigitalDoublesTradeFirstColumn from '../../Trade/components/DigitalDoubles/DigitalDoublesTradeFirstColumn'
import DigitalDoublesTradeSecondColumn from '../../Trade/components/DigitalDoubles/DigitalDoublesTradeSecondColumn'
import DigitalDoublesTradeThirdColumn from '../../Trade/components/DigitalDoubles/DigitalDoublesTradeThirdColumn'
import MoreDoublesTradeFirstColumn from '../../Trade/components/MoreDoubles/MoreDoublesTradeFirstColumn'
import MoreDoublesTradeSecondColumn from '../../Trade/components/MoreDoubles/MoreDoublesTradeSecondColumn'
import MoreDoublesTradeThirdColumn from '../../Trade/components/MoreDoubles/MoreDoublesTradeThirdColumn'
import TouchOptionsFirstColumn from '../../Trade/components/TouchOptions/TouchOptionsFirstColumn'
import TouchOptionsSecondColumn from '../../Trade/components/TouchOptions/TouchOptionsSecondColumn'
import TouchOptionsThirdColumn from '../../Trade/components/TouchOptions/TouchOptionsThirdColumn'
import EnhancedReverseOptionFirstColumn from '../../Trade/components/EnhancedReverseOption/EnhancedReverseOptionFirstColumn'
import EnhancedReverseOptionSecondColumn from '../../Trade/components/EnhancedReverseOption/EnhancedReverseOptionSecondColumn'
import EnhancedReverseOptionThirdColumn from '../../Trade/components/EnhancedReverseOption/EnhancedReverseOptionThirdColumn'
import RemainingOptionsTradeFirstColumn from '../../Trade/components/RemainingOptions/RemainingOptionsTradeFirstColumn'
import RemainingOptionsTradeSecondColumn from '../../Trade/components/RemainingOptions/RemainingOptionsTradeSecondColumn'
import RemainingOptionsTradeThirdColumn from '../../Trade/components/RemainingOptions/RemainingOptionsTradeThirdColumn'

const useDealSetting = ({
  form,
  setForm,
  page,
  setPage,
  currencyInitial,
  currencyData,
  errors,
  setErrors
}) => {
  const validateWindowsStartAndWindowsEnd = () => {
    if (form.window_start === '' && form.window_end === '') {
      return true
    }

    if (
      !(
        dayjs(form.window_start).isBefore(dayjs(form.window_end)) ||
        dayjs(form.window_start).isSame(dayjs(form.window_end))
      )
    ) {
      setErrors({ ...errors, window_start: "date can't be after window end" })
      return false
    }
    return true
  }

  const disabled = () => {
    const disabledField = control => {
      if (['string', 'number'].includes(typeof control)) {
        return !control
      } else if (typeof control === 'object') {
        return !control.name
      }
    }
    let deal
    switch (form?.deal_type?.deal_type_group) {
      case DEAL_GROUP.SPOT: {
        deal = new DealSpot(form)
        break
      }
      case DEAL_GROUP.VANILLA_OPTION:
        if (form.deal_type.deal_type_group === DEAL_GROUP.VANILLA_OPTION && page === false) {
          deal = new ClientDealVanillaForm(form)
        } else {
          deal = new DealVanilla(form)
        }
        break
      case DEAL_GROUP.DIGITAL_OPTION:
        if (form.deal_type.deal_type_group === DEAL_GROUP.DIGITAL_OPTION && page === false) {
          deal = new ClientDealDigitalOptionForm(form)
        } else {
          deal = new DealDigitalOption(form)
        }
        break
      case DEAL_GROUP.DIGITAL_KNOCK_IN_KNOCK_OUTS:
        if (
          form.deal_type.deal_type_group === DEAL_GROUP.DIGITAL_KNOCK_IN_KNOCK_OUTS &&
          page === false
        ) {
          deal = new ClientDealDigitalKnockInKnockOutForm(form)
        } else {
          deal = new DealDigitalKnockInKnockOut(form)
        }
        break
      case DEAL_GROUP.DIGITAL_DOUBLES:
        if (form.deal_type.deal_type_group === DEAL_GROUP.DIGITAL_DOUBLES && page === false) {
          deal = new ClientDealDigitalDoublesForm(form)
        } else {
          deal = new DealDigitalDoubles(form)
        }
        break
      case DEAL_GROUP.DOUBLE_NO_TOUCH_OPTION:
        if (
          form.deal_type.deal_type_group === DEAL_GROUP.DOUBLE_NO_TOUCH_OPTION &&
          page === false
        ) {
          deal = new ClientDealDoubleNoTouchOptionForm(form)
        } else {
          deal = new DealDoubleNoTouchOption(form)
        }
        break
      case DEAL_GROUP.MORE_DOUBLES:
        if (form.deal_type.deal_type_group === DEAL_GROUP.MORE_DOUBLES && page === false) {
          deal = new ClientDealMoreDoublesForm(form)
        } else {
          deal = new DealMoreDoubles(form)
        }
        break
      case DEAL_GROUP.TOUCH_OPTIONS:
        if (form.deal_type.deal_type_group === DEAL_GROUP.TOUCH_OPTIONS && page === false) {
          deal = new ClientDealTouchOptionsForm(form)
        } else {
          deal = new DealTouchOptions(form)
        }
        break
      case DEAL_GROUP.ENHANCED_REVERSE_OPTIONS:
        if (
          form.deal_type.deal_type_group === DEAL_GROUP.ENHANCED_REVERSE_OPTIONS &&
          page === false
        ) {
          deal = new ClientDealEnhancedReverseOptionsForm(form)
        } else {
          deal = new DealEnhancedReverseOptions(form)
        }
        break
      case DEAL_GROUP.REMAINING_OPTIONS:
        if (form.deal_type.deal_type_group === DEAL_GROUP.REMAINING_OPTIONS && page === false) {
          deal = new ClientDealRemainingOptionsForm(form)
        } else {
          deal = new DealRemainingOptions(form)
        }
        break
      default:
        return true
    }

    return Object.values(deal).some(disabledField)
  }

  const submitForm = () => {
    if (form.deal_type.deal_type_group !== DEAL_GROUP.SPOT && page === false) {
      //check window start window end
      const validationImprove = validateWindowsStartAndWindowsEnd()
      //if validation improve and also the currency pair is correct can move to next page
      if (validationImprove && !errors.currency_pair) {
        if (errors.window_start) {
          setErrors({})
        }
        setPage(true)
      }
    } else {
      switch (form.deal_type.deal_type_group) {
        case DEAL_GROUP.SPOT: {
          const deal = new DealSpot(form)
          webSocketWorkerEvent.sendEvent({
            type: 'create_deal',
            data: deal
          })
          break
        }

        case DEAL_GROUP.VANILLA_OPTION: {
          const deal = new DealVanilla(form)
          webSocketWorkerEvent.sendEvent({
            type: 'create_deal',
            data: deal
          })
          break
        }

        case DEAL_GROUP.DIGITAL_OPTION: {
          const deal = new DealDigitalOption(form)
          webSocketWorkerEvent.sendEvent({
            type: 'create_deal',
            data: deal
          })
          break
        }

        case DEAL_GROUP.DIGITAL_KNOCK_IN_KNOCK_OUTS: {
          const deal = new DealDigitalKnockInKnockOut(form)
          webSocketWorkerEvent.sendEvent({
            type: 'create_deal',
            data: deal
          })
          break
        }

        case DEAL_GROUP.DOUBLE_NO_TOUCH_OPTION: {
          const deal = new DealDoubleNoTouchOption(form)
          webSocketWorkerEvent.sendEvent({
            type: 'create_deal',
            data: deal
          })
          break
        }

        case DEAL_GROUP.DIGITAL_DOUBLES: {
          const deal = new DealDigitalDoubles(form)
          webSocketWorkerEvent.sendEvent({
            type: 'create_deal',
            data: deal
          })
          break
        }

        case DEAL_GROUP.MORE_DOUBLES: {
          const deal = new DealMoreDoubles(form)
          webSocketWorkerEvent.sendEvent({
            type: 'create_deal',
            data: deal
          })
          break
        }

        case DEAL_GROUP.TOUCH_OPTIONS: {
          const deal = new DealTouchOptions(form)
          webSocketWorkerEvent.sendEvent({
            type: 'create_deal',
            data: deal
          })
          break
        }

        case DEAL_GROUP.ENHANCED_REVERSE_OPTIONS: {
          const deal = new DealEnhancedReverseOptions(form)
          webSocketWorkerEvent.sendEvent({
            type: 'create_deal',
            data: deal
          })
          break
        }

        case DEAL_GROUP.REMAINING_OPTIONS: {
          const deal = new DealRemainingOptions(form)
          webSocketWorkerEvent.sendEvent({
            type: 'create_deal',
            data: deal
          })
          break
        }
        default:
          return
      }
    }
  }

  const resetForm = dealType => {
    const newForm = {
      deal_type: dealType,
      client_side: '',
      base_currency: '',
      counter_currency: '',
      currency_pair: '',
      quantity: '',
      client_price: '',
      market_maker_price: '',
      trade_date: dayjs().format(DATE_FORMAT.REGULAR_DATE_TIME),
      client_value_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
      market_maker_value_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
      client: '',
      market_maker: '',
      client_premium_amount: '',
      market_maker_premium_amount: '',
      client_premium_currency: currencyInitial,
      market_maker_premium_currency: currencyInitial,
      client_premium_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
      market_maker_premium_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
      settlement_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
      expiry: dayjs().format(DATE_FORMAT.REGULAR_DATE),
      put_call: '',
      cut: '',
      strike: '',
      client_option_payment_currency: currencyInitial,
      market_maker_option_payment_currency: currencyInitial,
      option_exercise_style: '',
      fixing_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
      fixing_source: '',
      client_trader_name: '',
      market_maker_trader_name: '',
      window_start: dayjs().format(DATE_FORMAT.REGULAR_DATE),
      window_end: dayjs().format(DATE_FORMAT.REGULAR_DATE),
      lower_barrier: '',
      upper_barrier: '',
      barrier: '',
      spot_ref: '',
      timing: '',
      payout_amount: '',
      payout_currency: currencyInitial,
      trade_origin: 'FXER'
    }
    setForm(newForm)
    setPage(false)
  }

  const handelBackButtonVanillaDeal = () => {
    setPage(false)
  }

  const handelFormChange = (event, element) => {
    const copyForm = { ...form }
    let { name, value } = event.target
    if (
      [
        'client',
        'deal_type',
        'payout_currency',
        'market_maker',
        'trade_origin',
        'cut',
        'fixing_source',
        'client_premium_currency',
        'market_maker_premium_currency',
        'client_option_payment_currency',
        'market_maker_option_payment_currency',
        'client_trader_name',
        'market_maker_trader_name'
      ].includes(name)
    ) {
      if (name === 'deal_type') {
        return resetForm(element)
      }
      copyForm[name] = element
    } else if (
      [
        'quantity',
        'strike',
        'client_price',
        'market_maker_price',
        'client_premium_amount',
        'market_maker_premium_amount',
        'payout_amount',
        'spot_ref',
        'barrier',
        'lower_barrier',
        'upper_barrier'
      ].includes(name) &&
      ((!isNaN(value) && +value > 0) || value === '')
    ) {
      if (value === '') {
        copyForm[name] = value
      } else {
        copyForm[name] = Number(value)
      }
    } else if (
      ['currency_pair', 'put_call', 'option_exercise_style', 'client_side', 'timing'].includes(name)
    ) {
      if (value === 'Spot' && page === true) {
        setPage(0)
      }
      if (name === 'currency_pair') {
        if (value.length < 7) {
          copyForm[name] = value.toUpperCase()
        }
      } else {
        copyForm[name] = value
      }
    }

    setForm(copyForm)
  }

  const handelDateChange = (name, value) => {
    const copyForm = { ...form }
    if (
      [
        'trade_date',
        'client_value_date',
        'market_maker_value_date',
        'client_premium_date',
        'market_maker_premium_date',
        'settlement_date',
        'expiry',
        'fixing_date',
        'window_start',
        'window_end'
      ].includes(name)
    ) {
      copyForm[name] = value
      setForm(copyForm)
    }
  }

  const handelFocusCurrencyPair = () => {
    setErrors({ ...errors, currency_pair: '' })
  }

  const handelBlurCurrencyPair = () => {
    if (form.currency_pair !== '') {
      if (form.currency_pair.length !== 6) {
        setErrors({ ...errors, currency_pair: 'currency too short' })
        return
      }
      const baseCurrency = form.currency_pair.substring(0, 3)
      const counterCurrency = form.currency_pair.substring(3, 6)

      const errorBaseCurrency = currencyData.data.find(
        ({ name: currency }) => currency === baseCurrency
      )
      const errorCounterCurrency = currencyData.data.find(
        ({ name: currency }) => currency === counterCurrency
      )

      if (errorBaseCurrency && errorCounterCurrency && errorBaseCurrency === errorCounterCurrency) {
        setErrors({ ...errors, currency_pair: "currencies can't be the same" })
        return
      }

      if (!errorBaseCurrency && !errorCounterCurrency) {
        setErrors({ ...errors, currency_pair: 'currencies not found' })
        return
      }

      if (!errorBaseCurrency) {
        setErrors({ ...errors, currency_pair: 'base currency not found' })
        return
      }

      if (!errorCounterCurrency) {
        setErrors({ ...errors, currency_pair: 'counter currency not found' })
        return
      }
    }
  }

  const disabledField = () => {
    const disabledFormField = {
      put_call: false,
      fixing_source: false,
      fixing_date: false,
      strike: false
    }

    switch (form?.deal_type?.deal_type_group) {
      case DEAL_GROUP.DOUBLE_NO_TOUCH_OPTION: {
        disabledFormField.fixing_date = true
        disabledFormField.fixing_source = true
        disabledFormField.strike = true
        disabledFormField.put_call = true
        return disabledFormField
      }
      case DEAL_GROUP.MORE_DOUBLES: {
        disabledFormField.fixing_date = true
        disabledFormField.fixing_source = true
        return disabledFormField
      }
      case DEAL_GROUP.REMAINING_OPTIONS: {
        disabledFormField.fixing_date = true
        disabledFormField.fixing_source = true
        return disabledFormField
      }
      case DEAL_GROUP.TOUCH_OPTIONS: {
        disabledFormField.fixing_date = true
        disabledFormField.fixing_source = true
        disabledFormField.strike = true
        disabledFormField.put_call = true
        return disabledFormField
      }
      default:
        return disabledFormField
    }
  }

  const exoticDealBody = () => {
    const disabled = page
    switch (form?.deal_type?.deal_type_group) {
      case DEAL_GROUP.DIGITAL_OPTION:
        return [
          <DigitalOptionTradeFirstColumn
            key={1}
            form={form}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />,
          <DigitalOptionTradeSecondColumn
            key={2}
            form={form}
            currencyData={currencyData}
            currencyInitial={currencyInitial}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />,
          <DigitalOptionTradeThirdColumn
            key={3}
            form={form}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />
        ]
      case DEAL_GROUP.DIGITAL_KNOCK_IN_KNOCK_OUTS:
        return [
          <DigitalKnockInKnockOutTradeFirstColumn
            key={1}
            form={form}
            errors={errors}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />,
          <DigitalKnockInKnockOutTradeSecondColumn
            key={2}
            form={form}
            currencyData={currencyData}
            currencyInitial={currencyInitial}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />,
          <DigitalKnockInKnockOutTradeThirdColumn
            key={3}
            form={form}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />
        ]

      case DEAL_GROUP.DOUBLE_NO_TOUCH_OPTION:
        return [
          <DoubleNoTouchOptionFirstColumn
            key={1}
            form={form}
            errors={errors}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />,
          <DoubleNoTouchOptionSecondColumn
            key={2}
            form={form}
            currencyData={currencyData}
            currencyInitial={currencyInitial}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />,
          <DoubleNoTouchOptionThirdColumn
            key={3}
            form={form}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />
        ]
      case DEAL_GROUP.DIGITAL_DOUBLES:
        return [
          <DigitalDoublesTradeFirstColumn
            key={1}
            form={form}
            errors={errors}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />,
          <DigitalDoublesTradeSecondColumn
            key={2}
            form={form}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            currencyData={currencyData}
            currencyInitial={currencyInitial}
            disabled={disabled}
          />,
          <DigitalDoublesTradeThirdColumn
            form={form}
            handelFormChange={handelFormChange}
            key={3}
            disabled={disabled}
          />
        ]
      case DEAL_GROUP.MORE_DOUBLES:
        return [
          <MoreDoublesTradeFirstColumn
            key={1}
            form={form}
            errors={errors}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />,
          <MoreDoublesTradeSecondColumn
            key={2}
            form={form}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />,
          <MoreDoublesTradeThirdColumn
            key={3}
            form={form}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />
        ]

      case DEAL_GROUP.TOUCH_OPTIONS:
        return [
          <TouchOptionsFirstColumn
            key={1}
            form={form}
            errors={errors}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />,
          <TouchOptionsSecondColumn
            key={2}
            form={form}
            currencyData={currencyData}
            currencyInitial={currencyInitial}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />,
          <TouchOptionsThirdColumn
            key={3}
            form={form}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />
        ]

      case DEAL_GROUP.ENHANCED_REVERSE_OPTIONS:
        return [
          <EnhancedReverseOptionFirstColumn
            key={1}
            form={form}
            errors={errors}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />,
          <EnhancedReverseOptionSecondColumn
            key={2}
            form={form}
            handelDateChange={handelDateChange}
            disabled={disabled}
          />,
          <EnhancedReverseOptionThirdColumn
            key={3}
            form={form}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />
        ]

      case DEAL_GROUP.REMAINING_OPTIONS:
        return [
          <RemainingOptionsTradeFirstColumn
            key={1}
            form={form}
            errors={errors}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />,
          <RemainingOptionsTradeSecondColumn
            key={2}
            form={form}
            handelDateChange={handelDateChange}
            disabled={disabled}
          />,
          <RemainingOptionsTradeThirdColumn
            key={3}
            form={form}
            handelFormChange={handelFormChange}
            disabled={disabled}
          />
        ]
      default:
        return []
    }
  }

  const getContinueButtonTitle = () => {
    if ([DEAL_GROUP.SPOT, ''].includes(form.deal_type.deal_type_group)) {
      return 'Create Deal'
    } else {
      if (page === false) {
        return 'Market Side >'
      }
      if (page === true) {
        return 'Create Deal'
      }
    }
  }

  return {
    getContinueButtonTitle,
    disabled,
    disabledField,
    submitForm,
    handelDateChange,
    handelFormChange,
    exoticDealBody,
    handelBackButtonVanillaDeal,
    handelFocusCurrencyPair,
    handelBlurCurrencyPair
  }
}

export default useDealSetting
