import React, { useEffect, useRef, useState } from 'react'
import { PnlDailyContainer, PnlDailyText } from './DailyPnl.style'
import { webSocketWorkerEvent, webSocketWorkerPriceEvent } from '../../../../services'
import currencyList from '../../../../constants/currencyList'
import { createBloombergCurrency } from '../../../../helper'
import { useSelector } from 'react-redux'

const DailyPnl = () => {
  const [dealsPnl, setDealsPnlState] = useState([])
  const [pnl, setPnl] = useState('...')
  const productsPrice = useRef({})
  const [report, setReport] = useState(false)
  const token = useSelector(state => state.auth.token)

  useEffect(() => {
    webSocketWorkerPriceEvent.connectPricerWS(token)
  }, [])

  const initialBloombergCurrency = () => {
    const baseCurrencies = currencyList.map(({ base_currency }) => base_currency)
    const condition = dealsPnl.every(({ counter_currency }) =>
      baseCurrencies.includes(counter_currency)
    )
    if (condition) {
      return currencyList
    } else {
      const currencyBloombergToAdd = dealsPnl
        .filter(({ counter_currency }) => baseCurrencies.includes(counter_currency))
        .map(({ counter_currency, counter_currency_country }) => {
          return JSON.stringify({
            counter_currency,
            counter_currency_country
          })
        })
      const getUniqueCurrencyBloomberg = Array.from(new Set(currencyBloombergToAdd)).map(deal =>
        createBloombergCurrency(JSON.parse(deal))
      )
      return currencyList.concat(getUniqueCurrencyBloomberg)
    }
  }

  useEffect(() => {
    if (dealsPnl.length) {
      const bloombergCurrency = initialBloombergCurrency()
      webSocketWorkerPriceEvent.sendEvent(bloombergCurrency)
    }
  }, [dealsPnl])

  useEffect(() => {
    webSocketWorkerEvent.sendEvent({
      type: 'get_daily_pnl',
      data: {}
    })
  }, [])

  useEffect(() => {
    const handelMessage = message => {
      if (
        message.data.type === 'get_daily_pnl' &&
        message.data.status === 'ok' &&
        message.data.data
      ) {
        const { data } = message.data
        setDealsPnlState(data)
      } else if (message.data.type === 'add_currency_pair_price') {
        setReport(true)
      }
    }
    webSocketWorkerEvent.addEventListener('message', handelMessage)
    return () => webSocketWorkerEvent.removeEventListener('message', handelMessage)
  }, [webSocketWorkerEvent])

  useEffect(() => {
    const handelMessage = message => {
      if (
        message.data &&
        message.data.action === 'new' &&
        message.data.base_currency &&
        message.data.counter_currency &&
        message.data.LAST_PRICE
      ) {
        const { base_currency, counter_currency } = message.data
        if (!productsPrice.current[base_currency] && counter_currency === 'USD') {
          productsPrice.current[base_currency] = {
            price: message.data.LAST_PRICE,
            LAST_PRICE: message.data.LAST_PRICE,
            action: message.data.action,
            base_currency: message.data.base_currency,
            base_currency_country: message.data.base_currency_country,
            counter_currency: message.data.counter_currency,
            counter_currency_country: message.data.counter_currency_country,
            order_id: message.data.order_id,
            security: message.data.security
          }
        } else {
          productsPrice.current[base_currency].LAST_PRICE = message.data.LAST_PRICE
          productsPrice.current[base_currency].action = message.data.action
          productsPrice.current[base_currency].base_currency = message.data.base_currency
          productsPrice.current[base_currency].base_currency_country =
            message.data.base_currency_country
          productsPrice.current[base_currency].counter_currency = message.data.counter_currency
          productsPrice.current[base_currency].counter_currency_country =
            message.data.counter_currency_country
          productsPrice.current[base_currency].order_id = message.data.order_id
          productsPrice.current[base_currency].security = message.data.security
        }
        let baseCurrency = {}
        let allDealIn = true

        for (const { counter_currency } of dealsPnl) {
          if (counter_currency === 'USD') {
            continue
          }
          if (counter_currency !== 'USD') {
            baseCurrency[counter_currency] = 0
          }
          if (productsPrice.current[counter_currency]) {
            baseCurrency[counter_currency] = 1
          } else {
            allDealIn = false
          }
        }
        let totalPnl = 0
        if (allDealIn) {
          for (const deal of dealsPnl) {
            const { counter_currency, total_price } = deal
            if (counter_currency === 'USD') {
              totalPnl += total_price
            } else {
              totalPnl += total_price * productsPrice.current[counter_currency]?.LAST_PRICE
              if (isNaN(totalPnl)) {
                return
              }
            }
          }
          setPnl(totalPnl)
        }
      }

      if (report === true) {
        let baseCurrency = {}
        let allDealIn = true
        for (const { base_currency } of currencyList) {
          if (base_currency === 'USD') {
            continue
          }
          if (base_currency !== 'USD') {
            baseCurrency[base_currency] = 0
          }
          if (productsPrice.current[base_currency]) {
            baseCurrency[base_currency] = 1
          } else {
            allDealIn = false
          }
        }

        if (allDealIn) {
          webSocketWorkerEvent.sendEvent({
            type: 'add_currency_pair_price',
            data: productsPrice.current
          })
          webSocketWorkerPriceEvent.removeEventListener('message', handelMessage)
          setReport(false)
        }
      }
    }
    webSocketWorkerPriceEvent.addEventListener('message', handelMessage)
    return () => webSocketWorkerPriceEvent.removeEventListener('message', handelMessage)
  }, [webSocketWorkerPriceEvent, report])

  useEffect(() => {
    const handelMessage = message => {
      if (message.data && message.data.security === 'connection_status' && message.data.status) {
        const bloombergCurrency = initialBloombergCurrency()
        webSocketWorkerPriceEvent.sendEvent(bloombergCurrency)
      }
    }
    webSocketWorkerPriceEvent.addEventListener('message', handelMessage)
    return () => webSocketWorkerPriceEvent.removeEventListener('message', handelMessage)
  }, [webSocketWorkerPriceEvent])

  return (
    <PnlDailyContainer>
      <PnlDailyText>Total P&L {pnl}</PnlDailyText>
    </PnlDailyContainer>
  )
}

export default DailyPnl
