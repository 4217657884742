import React from 'react'
import PropTypes from 'prop-types'
import { FormError, InputBaseStyle } from '../../../../ModalApp.style'
import { Box } from '@mui/material'
import { Label } from '../../../../../styled/global.style'
import { TextFieldStyle } from '../../../../../../pages/TradeBlotter/components/FilterTable/FilterTable.style'

const MoreDoublesTradeFirstColumn = ({
  form,
  handelFormChange,
  handelDateChange,
  disabled = false,
  errors
}) => {
  return (
    <>
      <Box width={'100%'} >
        <Label>Window Start *</Label>
        <TextFieldStyle
          type="date"
          variant="outlined"
          value={form.window_start}
          myerror={String(!!errors.window_start)}
          disabled={disabled}
          onChange={event => handelDateChange('window_start', event.target.value)}
        />
        {errors.window_start && <FormError>{errors.window_start}</FormError>}
      </Box>
      <Box width={'100%'}>
        <Label>Lower Barrier *</Label>
        <InputBaseStyle
          name="lower_barrier"
          placeholder="0.00"
          disabled={disabled}
          value={form.lower_barrier}
          onChange={handelFormChange}
        />
      </Box>
    </>
  )
}

export default MoreDoublesTradeFirstColumn

MoreDoublesTradeFirstColumn.propTypes = {
  disabled: PropTypes.bool,
  handelFormChange: PropTypes.func,
  handelDateChange: PropTypes.func,
  form: PropTypes.object,
  errors: PropTypes.object
}
