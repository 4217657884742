import { MenuItem } from '@mui/material'
import React, { useMemo } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ButtonAddNew } from '../../../../components/styled/global.style'
import { useDispatch } from 'react-redux'
import { setModal } from '../../../../redux/slices/Modal/modalSlice'
import { ACTION, MODAL_TITLE, MODAL_TYPE } from '../../../../utils/constants'
import { StyledMenu } from '../../TradeBlotter.style'

function MenuButton() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const MENU_ITEMS = useMemo(
    () => ({
      NEW_DEAL: 'New Deal',
      NEW_CLIENT_TRADE: 'New Client Trade',
      NEW_MARKET_TRADE: 'New Market Trade'
    }),
    []
  )
  const dispatch = useDispatch()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handelClick = event => {
    const key = event.target.innerText
    switch (key) {
      case MENU_ITEMS.NEW_DEAL:
        dispatch(
          setModal({
            type: MODAL_TYPE.DEAL,
            title: MODAL_TITLE.NEW_DEAL,
            isOpen: true,
            action: ACTION.CREATE,
            data: { type: "Spot" }
          })
        )
        break
      case MENU_ITEMS.NEW_CLIENT_TRADE:
        dispatch(
          setModal({
            type: MODAL_TYPE.TRADE,
            title: MODAL_TITLE.NEW_CLIENT_TRADE,
            isOpen: true,
            action: ACTION.CREATE,
            data: { trade_type: "Client" }
          })
        )
        break
      case MENU_ITEMS.NEW_MARKET_TRADE:
        dispatch(
          setModal({
            type: MODAL_TYPE.TRADE,
            title: MODAL_TITLE.NEW_MARKET_TRADE,
            isOpen: true,
            action: ACTION.CREATE,
            data: { trade_type: "Market" }
          })
        )
        break
      default:
        break
    }

    setAnchorEl(null)
  }

  return (
    <>
      <ButtonAddNew
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        New Trade
      </ButtonAddNew>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose} backgroundcolor={'#39393C'}>
        <MenuItem onClick={handelClick} disableRipple name={MENU_ITEMS.NEW_DEAL}>
          New Deal
        </MenuItem>
        <MenuItem onClick={handelClick} disableRipple name={MENU_ITEMS.NEW_TRADE}>
          New Client Trade
        </MenuItem>
        <MenuItem onClick={handelClick} disableRipple name={MENU_ITEMS.NEW_TRADE}>
          New Market Trade
        </MenuItem>
      </StyledMenu>
    </>
  )
}

export default MenuButton
