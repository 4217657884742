import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import useAxios from '../../../../hooks/useAxios.js'
import { DATE_FORMAT, routes } from '../../../../utils/constants.js'
import {
  AutocompleteStyle,
  Container,
  ModalContainer,
  SubContainer,
  TextFieldModalStyle
} from '../../ModalApp.style.js'
import ModalBottom from '../../components/ModalBottom/ModalBottom.js'
import { checkSomeLoader } from '../../../../helper/index.js'
import Loader from '../../../Loader/Loader.js'
import dayjs from 'dayjs'
import { webSocketWorkerEvent } from '../../../../services/index.js'
import { useDispatch } from 'react-redux'
import { handelNotifications } from '../../../../redux/slices/Notification/notificationSlice.js'
import { closeModal } from '../../../../redux/slices/Modal/modalSlice.js'
import { Label } from '../../../styled/global.style.js'
import SpotDealFirstColumn from './components/SpotDeal/SpotDealFirstColumn.js'
import SpotDealSecondColumn from './components/SpotDeal/SpotDealSecondColumn.js'
import VanillaDealFirstColumnClientSide from './components/VanillaDealClientSide/VanillaDealFirstColumnClientSide.js'
import VanillaDealSecondColumnClientSide from './components/VanillaDealClientSide/VanillaDealSecondColumnClientSide.js'
import VanillaDealThirdColumnClientSide from './components/VanillaDealClientSide/VanillaDealThirdColumnClientSide.js'
import VanillaDealFirstColumnMarketMakerSide from './components/VanillaDealMarketMakerSide/VanillaDealFirstColumnMarketMakerSide.js'
import VanillaDealSecondColumnMarketMakerSide from './components/VanillaDealMarketMakerSide/VanillaDealSecondColumnMarketMakerSide.js'
import VanillaDealThirdColumnMarketMakerSide from './components/VanillaDealMarketMakerSide/VanillaDealThirdColumnMarketMakerSide.js'

import ModalBottomVanillaDeal from '../../components/ModalBottom/ModalBottomVanillaDeal/ModalBottomVanillaDeal.js'
import PaperAutoComplete from '../../../PaperAutoComplete/PaperAutoComplete.js'
import useDealSetting from './hook/deal.setting.js'

const Deal = ({ data }) => {
  const [page, setPage] = useState(false)
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()
  let loaderFirstTime = useRef(true).current

  useEffect(() => {
    const handelMessage = message => {
      if (message.data.type === 'create_deal' && message.data.status === 'ok') {
        dispatch(
          handelNotifications({
            id: Date.now(),
            type: 'success',
            message: 'Deal Made Successfully'
          })
        )
        dispatch(closeModal())
      }
    }

    webSocketWorkerEvent.addEventListener('message', handelMessage)
    return () => webSocketWorkerEvent.removeEventListener('message', handelMessage)
  }, [webSocketWorkerEvent])

  const dealTypeInitial = { id: 0, name: '', deal_type_group: '' }
  const currencyInitial = { id: 0, name: '', code3: '' }

  const request1 = {
    endPoint: routes.currency
  }
  const request2 = {
    endPoint: routes.side
  }
  const request3 = {
    endPoint: routes.client
  }
  const request4 = {
    endPoint: routes.marketMaker
  }
  const request5 = {
    endPoint: routes.dealTypes
  }

  const request6 = {
    endPoint: routes.cut
  }

  const request7 = {
    endPoint: routes.fixingSource
  }

  const request8 = {
    endPoint: routes.trader
  }

  const { response: currencyData, loading: currencyLoader } = useAxios(request1)
  const { response: sides, loading: sidesLoader } = useAxios(request2)
  const { response: clients, loading: clientsLoader } = useAxios(request3)
  const { response: marketMakers, loading: marketMakersLoader } = useAxios(request4)
  const { response: dealTypes, loading: dealTypesLoader } = useAxios(request5)
  const { response: cut, loading: cutLoader } = useAxios(request6)
  const { response: fixingSource, loading: fixingSourceLoader } = useAxios(request7)
  const { response: traders, loading: tradersLoader } = useAxios(request8)

  const loaderList = [
    currencyLoader,
    sidesLoader,
    clientsLoader,
    marketMakersLoader,
    dealTypesLoader,
    cutLoader,
    fixingSourceLoader,
    tradersLoader
  ]

  const [form, setForm] = useState({
    deal_type: dealTypeInitial,
    client_side: '',
    currency_pair: '',
    quantity: '',
    client_price: '',
    market_maker_price: '',
    trade_date: dayjs().format(DATE_FORMAT.REGULAR_DATE_TIME),
    client_value_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
    market_maker_value_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
    client: '',
    market_maker: '',
    client_premium_amount: '',
    market_maker_premium_amount: '',
    client_premium_currency: currencyInitial,
    market_maker_premium_currency: currencyInitial,
    client_premium_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
    market_maker_premium_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
    settlement_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
    expiry: dayjs().format(DATE_FORMAT.REGULAR_DATE),
    put_call: '',
    cut: '',
    strike: '',
    client_option_payment_currency: currencyInitial,
    market_maker_option_payment_currency: currencyInitial,
    option_exercise_style: '',
    fixing_date: dayjs().format(DATE_FORMAT.REGULAR_DATE),
    fixing_source: '',
    client_trader_name: '',
    market_maker_trader_name: '',
    window_start: dayjs().format(DATE_FORMAT.REGULAR_DATE),
    window_end: dayjs().format(DATE_FORMAT.REGULAR_DATE),
    lower_barrier: '',
    upper_barrier: '',
    barrier: '',
    spot_ref: '',
    timing: '',
    payout_amount: '',
    payout_currency: currencyInitial,
    trade_origin: 'FXER'
  })

  const {
    getContinueButtonTitle,
    disabled,
    submitForm,
    handelDateChange,
    handelFormChange,
    disabledField,
    exoticDealBody,
    handelBackButtonVanillaDeal,
    handelFocusCurrencyPair,
    handelBlurCurrencyPair,
  } = useDealSetting({
    currencyData,
    currencyInitial,
    page,
    setPage,
    form,
    setForm,
    errors,
    setErrors
  })

  if (checkSomeLoader(loaderList) && loaderFirstTime) {
    loaderFirstTime = false
    return <Loader />
  }

  const exoticDeal = exoticDealBody()

  return (
    <ModalContainer>
      <Container container>
        <SubContainer item width={'225px'}>
          <Box width={'100%'}>
            <Label>Deal Type *</Label>
            {Array.isArray(dealTypes.data) && (
              <AutocompleteStyle
                onChange={(event, element) => {
                  event.target.name = 'deal_type'
                  element = element ?? dealTypeInitial
                  handelFormChange(event, element)
                }}
                size={'small'}
                value={form.trade_type}
                getOptionLabel={option => option.name}
                options={dealTypes.data}
                renderInput={params => (
                  <TextFieldModalStyle {...params} fullWidth placeholder="Select Deal" />
                )}
                ListboxComponent={PaperAutoComplete}
              />
            )}
          </Box>
          {['', 'Spot'].includes(form.deal_type.deal_type_group) && (
            <SpotDealFirstColumn
              currencyData={currencyData}
              currencyInitial={currencyInitial}
              form={form}
              errors={errors}
              handelFocusCurrencyPair={handelFocusCurrencyPair}
              handelBlurCurrencyPair={handelBlurCurrencyPair}
              handelFormChange={handelFormChange}
              clients={clients}
            />
          )}
          {!['', 'Spot'].includes(form.deal_type.deal_type_group) && page === false && (
            <VanillaDealFirstColumnClientSide
              clients={clients}
              form={form}
              errors={errors}
              traders={traders}
              disabledField={disabledField()}
              handelFocusCurrencyPair={handelFocusCurrencyPair}
              handelBlurCurrencyPair={handelBlurCurrencyPair}
              handelDateChange={handelDateChange}
              handelFormChange={handelFormChange}
              currencyData={currencyData}
              currencyInitial={currencyInitial}
            >
              {exoticDeal.length > 0 && <React.Fragment>{exoticDeal[0]}</React.Fragment>}
            </VanillaDealFirstColumnClientSide>
          )}
          {!['', 'Spot'].includes(form.deal_type.deal_type_group) && page === true && (
            <VanillaDealFirstColumnMarketMakerSide
              marketMakers={marketMakers}
              form={form}
              traders={traders}
              errors={errors}
              handelFocusCurrencyPair={handelFocusCurrencyPair}
              handelBlurCurrencyPair={handelBlurCurrencyPair}
              disabledField={disabledField()}
              handelDateChange={handelDateChange}
              handelFormChange={handelFormChange}
              currencyData={currencyData}
              currencyInitial={currencyInitial}
            >
              {exoticDeal.length > 0 && <React.Fragment>{exoticDeal[0]}</React.Fragment>}
            </VanillaDealFirstColumnMarketMakerSide>
          )}
        </SubContainer>
        {['', 'Spot'].includes(form.deal_type.deal_type_group) && (
          <SpotDealSecondColumn
            currencyData={currencyData}
            currencyInitial={currencyInitial}
            form={form}
            errors={errors}
            handelFormChange={handelFormChange}
            marketMakers={marketMakers}
            sides={sides}
          />
        )}
        {!['Spot', ''].includes(form.deal_type.deal_type_group) && page === true && (
          <VanillaDealSecondColumnMarketMakerSide
            clients={clients}
            form={form}
            sides={sides}
            traders={traders}
            disabledField={disabledField()}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            currencyData={currencyData}
            currencyInitial={currencyInitial}
          >
            {exoticDeal.length > 0 && <React.Fragment>{exoticDeal[1]}</React.Fragment>}
          </VanillaDealSecondColumnMarketMakerSide>
        )}

        {!['Spot', ''].includes(form.deal_type.deal_type_group) && page === false && (
          <VanillaDealSecondColumnClientSide
            clients={clients}
            form={form}
            sides={sides}
            traders={traders}
            disabledField={disabledField()}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            currencyData={currencyData}
            currencyInitial={currencyInitial}
          >
            {exoticDeal.length > 0 && <React.Fragment>{exoticDeal[1]}</React.Fragment>}
          </VanillaDealSecondColumnClientSide>
        )}

        {!['Spot', ''].includes(form.deal_type.deal_type_group) && page === false && (
          <VanillaDealThirdColumnClientSide
            putCall={[{ name: 'PUT' }, { name: 'CALL' }]}
            cut={cut}
            fixingSource={fixingSource}
            form={form}
            sides={sides}
            disabledField={disabledField()}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            currencyData={currencyData}
            currencyInitial={currencyInitial}
          >
            {exoticDeal.length > 0 && <React.Fragment>{exoticDeal[2]}</React.Fragment>}
          </VanillaDealThirdColumnClientSide>
        )}

        {!['Spot'].includes(form.deal_type.deal_type_group) && page === true && (
          <VanillaDealThirdColumnMarketMakerSide
            putCall={[{ name: 'PUT' }, { name: 'CALL' }]}
            cut={cut}
            fixingSource={fixingSource}
            form={form}
            sides={sides}
            traders={traders}
            disabledField={disabledField()}
            handelDateChange={handelDateChange}
            handelFormChange={handelFormChange}
            currencyData={currencyData}
            currencyInitial={currencyInitial}
          >
            {exoticDeal.length > 0 && <React.Fragment>{exoticDeal[2]}</React.Fragment>}
          </VanillaDealThirdColumnMarketMakerSide>
        )}
      </Container>

      <Box>
        {page === false && (
          <ModalBottom
            data={data}
            title={getContinueButtonTitle()}
            submitForm={submitForm}
            disabled={disabled()}
            justifyContent="space-between"
          />
        )}
        {!['', 'Spot'].includes(form.deal_type.deal_type_group) && page === true && (
          <ModalBottomVanillaDeal
            data={data}
            title={getContinueButtonTitle()}
            submitForm={submitForm}
            disabled={disabled()}
            handelClick={handelBackButtonVanillaDeal}
            justifyContent="space-between"
          />
        )}
      </Box>
    </ModalContainer>
  )
}

export default Deal

Deal.propTypes = {
  data: PropTypes.object
}
