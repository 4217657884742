import React from 'react'
import PropTypes from 'prop-types'
import Table from '../../../../components/Table/Table'
import { MODAL_TITLE, MODAL_TYPE, routes } from '../../../../utils/constants'
import axios from 'axios'
import { setModal } from '../../../../redux/slices/Modal/modalSlice'
import useAxios from '../../../../hooks/useAxios'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteIconStyle, EditIconStyle } from '../../../../components/styled/global.style'
import Loader from '../../../../components/Loader/Loader'
import { handelNotifications } from '../../../../redux/slices/Notification/notificationSlice'

const FixingSource = ({ params }) => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)

  const request = { endPoint: routes.fixingSource, params, modalType: MODAL_TYPE.FIXING_SOURCE }
  const { response, loading } = useAxios(request)

  const getFixingSourceById = async id => {
    const url = process.env.REACT_APP_SERVER_URL.concat(routes.fixingSource, '/', id)
    try {
      const result = await axios.get(url, { headers: { Authorization: token } })
      dispatch(
        setModal({
          type: MODAL_TYPE.FIXING_SOURCE,
          title: MODAL_TITLE.EDIT_FIXING_SOURCE,
          data: result.data,
          isOpen: true
        })
      )
    } catch (error) {
      dispatch(
        handelNotifications({
          id: Date.now(),
          type: 'error',
          message: 'Sorry Something Went Wrong'
        })
      )
    }
  }

  const deleteFixSourceById = async id => {
    const url = process.env.REACT_APP_SERVER_URL.concat(routes.fixingSource, '/', id)
    try {
      const result = await axios.delete(url)
      dispatch(
        handelNotifications({
          id: Date.now(),
          type: 'success',
          message: result.data.message
        })
      )
    } catch (error) {
      dispatch(
        handelNotifications({
          id: Date.now(),
          type: 'error',
          message: 'Sorry Something Went Wrong'
        })
      )
    }
  }

  const EditFixingSource = prop => {
    return <EditIconStyle onClick={() => getFixingSourceById(prop.data.id)} />
  }

  const DeleteFixingSource = prop => {
    return <DeleteIconStyle onClick={() => deleteFixSourceById(prop.data.id)} />
  }

  const columns = [
    {
      headerName: 'Fixing Source',
      field: 'name'
    },
    {
      flex: 1,
      resizable: false,
      suppressMovable: true,
      cellClass: 'suppress-movable-col'
    },
    { headerName: 'Edit', cellRenderer: EditFixingSource },
    { headerName: 'Delete', cellRenderer: DeleteFixingSource }
  ]
  if (loading === true) {
    return <Loader />
  }

  if (response.status === 200) {
    return <Table rows={response.data} columns={columns} />
  }
}

FixingSource.propTypes = { params: PropTypes.object, setParams: PropTypes.func }

export default FixingSource
