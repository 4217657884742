import { styled } from '@mui/system'
import { Box, Typography, MenuItem } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'

export const HeaderWarper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: `${theme.palette.tabs.backgroundColor.primary}`,
  borderTopLeftRadius: '20px',
  height: '65px',
  width: '100%',
  boxSizing: 'border-box',
  borderBottom: `1px solid  ${theme.palette.separators.primary}`,
  [theme.breakpoints.between('xs', 'sm')]: {
    width: '100%'
  }
}))

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  leadingTrim: 'both',
  padding: '12px 16px 12px 24px',
  fontFamily: 'Nunito',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  width:"100%",
  textTransform: 'capitalize'
}))

export const UserIcon = styled(AccountCircleIcon)(({ theme }) => ({
  fill: theme.palette.text.secondary,
  height: '32px',
  width: '32px',
  padding: '12px 0px',
  marginRight: '16px',
  [theme.breakpoints.down('md')]: {
    marginRight: '0px',
    padding: '0px'
  }
}))

export const StyleLogoutIcon = styled(LogoutIcon)(({ theme }) => ({
  color: theme.palette.button.delete.primary
}))

export const StyleMenuItem = styled(MenuItem)(({ theme }) => ({
  height: '40px',
  color: theme.palette.button.delete.primary,
  padding: '0px'
}))
