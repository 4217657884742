import React from 'react'
import PropTypes from 'prop-types'
import {
  ContainerRatesAgGridTable,
  GridWidget,
  TypographyWidgetStyle
} from '../../TradeBlotter.style'
import { Box, Grid } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { ReactComponent as Close } from '../../../../assets/close.svg'

const WidgetRates = ({ columnsDefs2, data, getRowId2, defaultColDef2, handleRemoveWidget }) => {
  return (
    <>
      <GridWidget>
        <TypographyWidgetStyle>Rates</TypographyWidgetStyle>
        <Grid marginTop={'auto'} marginBottom={'auto'}>
          <Close style={{ marginRight: '10px' }} onClick={() => handleRemoveWidget('Rates')} />
        </Grid>
      </GridWidget>
      <ContainerRatesAgGridTable>
        <AgGridReact
          columnDefs={columnsDefs2}
          rowData={data}
          getRowId={getRowId2}
          defaultColDef={defaultColDef2}
        />
        <Box
          style={{
            backgroundColor: '#242428',
            width: '100%',
            paddingTop: '52px',
            display: 'flex',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px'
          }}
        />
      </ContainerRatesAgGridTable>
    </>
  )
}

export default WidgetRates

WidgetRates.propTypes = {
  columnsDefs2: PropTypes.array,
  data: PropTypes.array,
  getRowId2: PropTypes.func,
  defaultColDef2: PropTypes.object,
  handleRemoveWidget: PropTypes.func
}
