/* eslint-disable */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { MODAL_TYPE, routes } from '../../utils/constants.js'
import useAxios from '../../hooks/useAxios.js'
import Table from '../../components/Table/Table.js'
import Loader from '../../components/Loader/Loader.js'
import { CircleIconStyle } from '../../components/styled/global.style.js'
import { Link } from 'react-router-dom'
import { Grid } from '@mui/material'
import { TypographyStyle, TypographyEntityNameStyle } from './Entities.style.js'
import EntityDetails from './EntityDetails/EntityDetails.js'

const Entities = ({ params }) => {
  const [entityName, setEntityName] = useState({
    name: '',
    accountId: ''
  })
  const dispatch = useDispatch()

  const request = {
    endPoint: routes.entities,
    params,
    modalType: MODAL_TYPE.ENTITIES
  }
  const { response, loading } = useAxios(request)

  const ActiveClient = prop => {
    return <CircleIconStyle isactive={String(prop.data.active)} />
  }
  const updateEntityName = params => {
    setEntityName({
      name: params.data.entityName,
      accountId: params.data.accountId
    })
  }

  const entityLinkRenderer = params => {
    const entityName = params.value
    return (
      <Link
        onClick={() => updateEntityName(params)}
        style={{ textDecoration: 'none', color: 'white' }}
      >
        {entityName}
      </Link>
    )
  }

  const handleClick = () => {
    setEntityName({
      name: '',
      accountId: ''
    })
  }
  const columns = [
    { headerName: 'Account ID', field: 'accountId', flex: 1 },
    { headerName: 'Entity Name', field: 'entityName', flex: 1, cellRenderer: entityLinkRenderer },
    { headerName: 'Country', field: 'country', flex: 1 },
    { headerName: 'LEI', field: 'lei', flex: 1 },
    { headerName: 'Active', flex: 1, cellRenderer: ActiveClient }
  ]
  if (loading === true) {
    return <Loader />
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {entityName.name === '' ? (
          <Grid>
            {response.status === 200 ? (
              <Table rows={response.data} columns={columns} />
            ) : (
              <Table rows={[]} columns={columns}/>
            )}
          </Grid>
        ) : (
          <Grid>
            <EntityDetails entityName={entityName} />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

Entities.propTypes = { setParams: PropTypes.func, params: PropTypes.string }

export default Entities
