import { styled } from '@mui/system'
import { Box, Typography } from '@mui/material'

export const PnlDailyContainer = styled(Box)(({ theme }) => ({
  paddingRight: '4px',
  paddingLeft: '4px',
  borderRadius: '4px',
  height: '32px',
  display: 'flex',
  width: 'max-content',
  alignItems: 'center',
  backgroundColor: theme.palette.button.allocate.primary
}))

export const PnlDailyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.button.text.primary,
  fontSize: '14px',
  marginTop: '11px',
  marginBottom: '11px',
  marginLeft:"15px",
  lineHeight: '1px !important',
  marginRight:"15px",
}))
