import { styled } from '@mui/system'
import MuiDrawer from '@mui/material/Drawer'

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.button.save.primary,
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  minHeight: '65px !important'
}))
const drawerWidth = 240

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.separators.primary,
    borderRight: '0px'
  },
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',

  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))
