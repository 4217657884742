import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import {
  AutocompleteStyle,
  FormError,
  InputBaseStyle,
  TextFieldModalStyle
} from '../../../../ModalApp.style'
import PaperAutoComplete from '../../../../../PaperAutoComplete/PaperAutoComplete'
import { Label } from '../../../../../styled/global.style'
import { TextFieldStyle } from '../../../../../../pages/TradeBlotter/components/FilterTable/FilterTable.style'

const SpotDealFirstColumn = ({
  clients,
  form,
  handelFocusCurrencyPair,
  handelBlurCurrencyPair,
  handelFormChange,
  handelDateChange,
  errors
}) => {
  return (
    <>
      <Box width={'100%'} >
        <Label>Currency Pair *</Label>
        <InputBaseStyle
          value={form.currency_pair}
          name="currency_pair"
          onChange={handelFormChange}
          onFocus={handelFocusCurrencyPair}
          onBlur={handelBlurCurrencyPair}
          error={errors.currency_pair}
        />
        {errors.currency_pair !== '' && <FormError>{errors.currency_pair}</FormError>}
      </Box>
      <Box width={'100%'}>
        <Label>Quantity *</Label>
        <InputBaseStyle
          value={form.quantity}
          name="quantity"
          onChange={handelFormChange}
          placeholder="0.00"
        />
      </Box>
      <Box width={'100%'}>
        <Label>Client Value Date *</Label>
        <TextFieldStyle
          type="date"
          value={form.client_value_date}
          placeholder="dd-mm-yyyy"
          onChange={handelDateChange}
        />
      </Box>
      <Box width={'100%'}>
        <Label>Client *</Label>
        {Array.isArray(clients.data) && (
          <AutocompleteStyle
            onChange={(event, element) => {
              event.target.name = 'client'
              element = element ?? ''
              handelFormChange(event, element)
            }}
            size={'small'}
            value={form.client}
            options={clients.data.map(({ name }) => name)}
            renderInput={params => (
              <TextFieldModalStyle fullWidth {...params} placeholder={'Select Client'} />
            )}
            ListboxComponent={PaperAutoComplete}
          />
        )}
      </Box>
      <Box width={'100%'}>
        <Label>Client Price *</Label>
        <InputBaseStyle
          value={form.client_price}
          name="client_price"
          onChange={handelFormChange}
          placeholder="0.0000"
        />
      </Box>
    </>
  )
}

export default SpotDealFirstColumn

SpotDealFirstColumn.propTypes = {
  currencyData: PropTypes.object,
  clients: PropTypes.object,
  form: PropTypes.object,
  currencyInitial: PropTypes.object,
  handelFormChange: PropTypes.func,
  handelDateChange: PropTypes.func,
  errors: PropTypes.object,
  handelFocusCurrencyPair: PropTypes.func,
  handelBlurCurrencyPair: PropTypes.func
}
