import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, InputLabel } from '@mui/material'
import {
  StyledSelect,
  StyledMenuItem,
  StyledSelectPlaceHolder,
  StyledSelectArrowIcon
} from './SelectWithArrow.style'
import { useTheme } from '@emotion/react'
import './SelectWithArrow.css'

const SelectWithArrow = ({
  orderType,
  onChange,
  onBlur = () => {},
  items,
  label,
  name = '',
  disabled = false,
  placeholder = '',
  error = false,
  style = null
}) => {

  const theme = useTheme()
  const copyStyle = style ?? { background: theme.palette.separators.primary }
  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel>{label}</InputLabel>
      <StyledSelect
        displayEmpty
        name={name}
        size={'small'}
        error={error}
        renderValue={selected => {
          if (selected.length === 0) {
            return (
              <StyledSelectPlaceHolder disabled={String(disabled)}>
                {placeholder}
              </StyledSelectPlaceHolder>
            )
          }
          return selected
        }}
        value={orderType}
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        disabled={disabled}
        style={copyStyle}
        sx={{
          '&.Mui-error': {
            border: `1px solid ${theme.palette.button.delete.primary}`
          }
        }}
        MenuProps={{
          PaperProps: {
            style: {
              padding:"8px !important",
              overflowY: 'auto',
              maxHeight: '150px',
              backgroundColor: theme.palette.separators.primary,
              color: 'white'
            },
            className: {
              '&::-webkit-scrollbar': {
                width: '4px',
                height: '20px'
              },
              '&::-webkit-scrollbar-track': {
                background: '#707079',
                width: '6px',
                height: '6px'
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#babec7',
                borderRadius: '4px',
                width: '6px',
                height: '6px'
              }
            }
          }
        }}
        IconComponent={StyledSelectArrowIcon}
      >
        {items.map((type, index) => (
          <StyledMenuItem key={index} value={type.name}>
            {type.name}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  )
}

export default SelectWithArrow

SelectWithArrow.propTypes = {
  orderType: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  items: PropTypes.array,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.bool,
  style: PropTypes.object
}
