import styled from '@emotion/styled'

import { FormLabel, IconButton, OutlinedInput } from '@mui/material'

export const CustomOutlinedInput = styled(OutlinedInput)(({ theme, costumeerror }) => {
  let border = 'none' // Default border style

  if (costumeerror === 'true') {
    border = `1px solid ${theme.palette.button.delete.primary} !important` // Apply error border style
  }
  return {
    backgroundColor: theme.palette.separators.primary,
    color: 'white',
    height: '32px',
    border: border, // Apply dynamically computed border style
    fontFamily: 'Nunito',
    fontSize: '14px',
    '& input:-webkit-autofill': {
      WebkitBoxShadow: `0 0 0 30 ${theme.palette.separators.primary} inset !important`, // Autofill style adjustment
      '-webkit-text-fill-color': 'inherit',
      transition: 'background-color 5000s ease-in-out 0s'
    },
    '&.Mui-focused': {
      '& fieldset': {
        borderColor: 'transparent' // Remove border color on focus
      }
    },
    '&:hover': {
      '& fieldset': {
        borderColor: 'transparent' // Remove border color on hover
      }
    },
    '&.MuiInputBase-root': {
      padding: '20px 0px',
      borderRadius: '4px'
    },
    '& .MuiInputBase-input': {
      backgroundColor: 'transparent !important',
      padding: '0px 12px'
    }
  }
})

export const FromLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '12px',
  marginBottom: '8px'
}))

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.button.save.primary,
  '&:hover': {
    color: theme.palette.button.save.hover
  }
}))
