import React from 'react'
import PropTypes from 'prop-types'
import {
  AutocompleteStyle,
  InputBaseStyle,
  SubContainer,
  TextFieldModalStyle
} from '../../../../ModalApp.style'
import { Box, Typography } from '@mui/material'
import PaperAutoComplete from '../../../../../PaperAutoComplete/PaperAutoComplete'
import CurrencyLogo from '../../../../../CurrencyLogo/CurrencyLogo'
import FlagCurrency from '../../../../../FlagCurrency/FlagCurrency'
import { Label } from '../../../../../styled/global.style'
import { TextFieldStyle } from '../../../../../../pages/TradeBlotter/components/FilterTable/FilterTable.style'
import { ButtonGroupStyle, OptionContainer, SideButton } from '../../Deal.style'

const VanillaDealSecondColumnClientSide = ({
  form,
  handelFormChange,
  handelDateChange,
  currencyData,
  sides,
  currencyInitial,
  children,
  disabledField,
  traders
}) => {
  return (
    <SubContainer item width={'225px'}>
      <Box width={'100%'}>
        <Label>Trade Type *</Label>
        <InputBaseStyle value={`Client ${form.deal_type.name}`} disabled />
      </Box>
      <Box width={'100%'}>
        <Label>Side *</Label>
        {Array.isArray(sides.data) && (
          <ButtonGroupStyle variant="outlined">
            {sides.data.map(({ name }, id) => (
              <SideButton
                name="client_side"
                key={id}
                value={name}
                select={String(form.client_side === name)}
                onClick={handelFormChange}
              >
                {name}
              </SideButton>
            ))}
          </ButtonGroupStyle>
        )}
      </Box>
      <Box width={'100%'}>
        <Label>Expiry Date *</Label>
        <TextFieldStyle
          type="date"
          variant="outlined"
          value={form.expiry}
          onChange={event => handelDateChange('expiry', event.target.value)}
        />
      </Box>
      <Box width={'100%'}>
        <Label>Fixing Date *</Label>
        <TextFieldStyle
          type="date"
          variant="outlined"
          disabled={disabledField.fixing_date}
          value={form.fixing_date}
          onChange={event => handelDateChange('fixing_date', event.target.value)}
        />
      </Box>
      <Box width={'100%'}>
        <Label>Premium Currency *</Label>
        {Array.isArray(currencyData.data) && (
          <AutocompleteStyle
            fullWidth
            onChange={(event, element) => {
              event.target.name = 'client_premium_currency'
              element = element ?? currencyInitial
              handelFormChange(event, element)
            }}
            size={'small'}
            value={form.client_premium_currency}
            getOptionLabel={option => option.name}
            options={currencyData.data}
            renderOption={(props, option) => (
              <OptionContainer {...props} key={option.id}>
                <FlagCurrency countryCode={option.code3} />
                <CurrencyLogo currencyCode={option.name} />
                <Typography>{option.name} </Typography>
              </OptionContainer>
            )}
            renderInput={params => (
              <TextFieldModalStyle fullWidth {...params} placeholder="Select Currency" />
            )}
            ListboxComponent={PaperAutoComplete}
          />
        )}
      </Box>

      <Box width={'100%'}>
        <Label>Option Payment Currency *</Label>
        {Array.isArray(currencyData.data) && (
          <AutocompleteStyle
            fullWidth
            onChange={(event, element) => {
              event.target.name = 'client_option_payment_currency'
              element = element ?? currencyInitial
              handelFormChange(event, element)
            }}
            size={'small'}
            value={form.client_option_payment_currency}
            getOptionLabel={option => option.name}
            options={currencyData.data}
            renderOption={(props, option) => (
              <OptionContainer {...props} key={option.id}>
                <FlagCurrency countryCode={option.code3} />
                <CurrencyLogo currencyCode={option.name} />
                <Typography>{option.name} </Typography>
              </OptionContainer>
            )}
            renderInput={params => (
              <TextFieldModalStyle fullWidth {...params} placeholder="Select Currency" />
            )}
            ListboxComponent={PaperAutoComplete}
          />
        )}
      </Box>
      <Box width={'100%'}>
        <Label>Trader *</Label>
        {Array.isArray(traders.data) && (
          <AutocompleteStyle
            onChange={(event, element) => {
              event.target.name = 'client_trader_name'
              element = element ?? ''
              handelFormChange(event, element)
            }}
            size={'small'}
            value={form.client_trader_name}
            getOptionLabel={option => option}
            options={traders.data.map(({ trader_name }) => trader_name)}
            renderInput={params => (
              <TextFieldModalStyle {...params} fullWidth placeholder="Select Trader" />
            )}
            ListboxComponent={PaperAutoComplete}
          />
        )}
      </Box>
      {children}
    </SubContainer>
  )
}

export default VanillaDealSecondColumnClientSide

VanillaDealSecondColumnClientSide.propTypes = {
  counterParty: PropTypes.object,
  currencyData: PropTypes.object,
  currencyInitial: PropTypes.object,
  handelFormChange: PropTypes.func,
  handelDateChange: PropTypes.func,
  sides: PropTypes.object,
  form: PropTypes.object,
  disabledField: PropTypes.object,
  traders:PropTypes.object,
  children: PropTypes.node
}
