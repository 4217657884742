import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import {
  AutocompleteStyle,
  InputBaseStyle,
  SubContainer,
  TextFieldModalStyle
} from '../../../../ModalApp.style'
import PaperAutoComplete from '../../../../../PaperAutoComplete/PaperAutoComplete'
import { Label } from '../../../../../styled/global.style'
import { TextFieldStyle } from '../../../../../../pages/TradeBlotter/components/FilterTable/FilterTable.style'
import { ButtonGroupStyle, SideButton } from '../../Deal.style'

const SpotDealSecondColumn = ({
  form,
  sides,
  marketMakers,
  handelFormChange,
  handelDateChange
}) => {
  return (
    <SubContainer item width={'225px'}>
      <Box width={'100%'}>
        <Label>Side *</Label>
        {Array.isArray(sides.data) && (
          <ButtonGroupStyle variant="outlined">
            {sides.data.map(({ name }, id) => (
              <SideButton
                name="client_side"
                key={id}
                value={name}
                select={String(form.client_side === name)}
                onClick={handelFormChange}
              >
                {name}
              </SideButton>
            ))}
          </ButtonGroupStyle>
        )}
      </Box>
      <Box width={'100%'}>
        <Label>Trade Date *</Label>
        <TextFieldStyle type="datetime-local" value={form.trade_date} disabled />
      </Box>

      <Box width={'100%'}>
        <Label>Market Price *</Label>
        <InputBaseStyle
          value={form.market_maker_price}
          name="market_maker_price"
          onChange={handelFormChange}
          placeholder="0.0000"
        />
      </Box>
      <Box width={'100%'}>
        <Label>Market Value Date *</Label>
        <Box>
          <TextFieldStyle
            type="date"
            variant="outlined"
            placeholder="dd-mm-yyyy"
            value={form.market_maker_value_date}
            onChange={event => handelDateChange('value_date', event.target.value)}
          />
        </Box>
      </Box>
      <Box width={'100%'}>
        <Label>Market Maker *</Label>
        {Array.isArray(marketMakers.data) && (
          <AutocompleteStyle
            onChange={(event, element) => {
              event.target.name = 'market_maker'
              element = element ?? ''
              handelFormChange(event, element)
            }}
            size={'small'}
            value={form.market_maker}
            options={marketMakers.data.map(({ name }) => name)}
            renderInput={params => (
              <TextFieldModalStyle fullWidth {...params} placeholder={'Select Market Maker'} />
            )}
            ListboxComponent={PaperAutoComplete}
          />
        )}
      </Box>
    </SubContainer>
  )
}

export default SpotDealSecondColumn

SpotDealSecondColumn.propTypes = {
  currencyData: PropTypes.object,
  marketMakers: PropTypes.object,
  sides: PropTypes.object,
  handelDateChange: PropTypes.func,
  form: PropTypes.object,
  currencyInitial: PropTypes.object,
  handelFormChange: PropTypes.func
}
