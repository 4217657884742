import React, { Suspense, lazy } from 'react'
import { styled } from '@mui/system'
import { Modal as BaseModal } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { closeModal } from '../../redux/slices/Modal/modalSlice'
import { MODAL_TYPE } from '../../utils/constants'
import ModalHeader from './components/ModalHeader/ModalHeader'
import { ModalContent } from './ModalApp.style'
import Loader from '../Loader/Loader'
import Deal from './children/Deal/Deal'

const FixingSource = lazy(() => import('./children/FixingSource/FixingSource'))
const MarketMakers = lazy(() => import('./children/MarketMakers/MarketMakers'))
const Cuts = lazy(() => import('./children/Cuts/Cuts'))
const CurrencyPair = lazy(() => import('./children/CurrencyPair/CurrencyPair'))
const PrimeBroker = lazy(() => import('./children/PrimeBroker/PrimeBroker'))
const User = lazy(() => import('./children/User/User'))
const Client = lazy(() => import('./children/Client/Client'))
const Trade = lazy(() => import('./children/Trade/Trade'))
const AllocateBlock = lazy(() => import('./children/AllocateBlock/AllocateBlock'))
const DealReportStatus = lazy(() => import('./children/DealReportStaus/DealReportStatus'))

function ModalApp() {
  const modal = useSelector(state => state.modal)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(closeModal())
  }

  const modalBody = () => {
    switch (modal.type) {
      case MODAL_TYPE.CLIENT:
        return <Client data={modal.data} />
      case MODAL_TYPE.MARKET_MAKERS:
        return <MarketMakers data={modal.data} />
      case MODAL_TYPE.PRIME_BROKER:
        return <PrimeBroker data={modal.data} />
      case MODAL_TYPE.FIXING_SOURCE:
        return <FixingSource data={modal.data} />
      case MODAL_TYPE.CUTS:
        return <Cuts data={modal.data} />
      case MODAL_TYPE.CURRENCY_PAIRS:
        return <CurrencyPair data={modal.data} />
      case MODAL_TYPE.USERS:
        return <User data={modal.data} />
      case MODAL_TYPE.TRADE:
        return <Trade data={modal.data} action={modal.action} disabled={modal.disabled} />
      case MODAL_TYPE.DEAL:
        return <Deal data={modal.data} />
      case MODAL_TYPE.ALLOCATE_BLOCK:
        return <AllocateBlock data={modal.data} />
      case MODAL_TYPE.ENTITIES:
        return <AllocateBlock data={modal.data} />
      case MODAL_TYPE.DEAL_REPORT_STATUS:
        return <DealReportStatus data={modal.data} />
      default:
        return <></>
    }
  }

  const modalHeader = () => {
    switch (modal.type) {
      case MODAL_TYPE.CLIENT:
        return (
          <ModalHeader
            title={modal.title}
            dividerStyle={{ marginTop: '32px', marginBottom: '32px' }}
          />
        )
      case MODAL_TYPE.MARKET_MAKERS:
        return (
          <ModalHeader
            title={modal.title}
            dividerStyle={{ marginTop: '32px', marginBottom: '32px' }}
          />
        )
      case MODAL_TYPE.PRIME_BROKER:
        return <ModalHeader title={modal.title} />
      case MODAL_TYPE.FIXING_SOURCE:
        return <ModalHeader title={modal.title} />
      case MODAL_TYPE.CUTS:
        return <ModalHeader title={modal.title} />
      case MODAL_TYPE.CURRENCY_PAIRS:
        return <ModalHeader title={modal.title} />
      case MODAL_TYPE.USERS:
        return <ModalHeader title={modal.title} />
      case MODAL_TYPE.TRADE:
        return <ModalHeader title={modal.title} />
      case MODAL_TYPE.DEAL:
        return <ModalHeader title={modal.title} />
      case MODAL_TYPE.ALLOCATE_BLOCK:
        return <ModalHeader title={modal.title} />
      case MODAL_TYPE.DEAL_REPORT_STATUS:
        return (
          <ModalHeader
            title={modal.title}
            dividerStyle={{ marginBottom: '12px', marginTop: '12px' }}
            type={MODAL_TYPE.DEAL_REPORT_STATUS}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <Suspense fallback={<Loader />}>
      <Modal open={modal.isOpen} onClose={handleClose} className='modal'>
        <ModalContent type={modal.type}>
          {modalHeader()}
          {modalBody()}
        </ModalContent>
      </Modal>
    </Suspense>
  )
}

const Modal = styled(BaseModal)(({ theme }) => ({
  zIndex: 1300,
  right: '50',
  bottom: '50',
  position: 'absolute',
  border: '2px solid #000',
  boxShadow: 24,
  top: '50',
  left: '50',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    minHeight: '100dvh',
    height: '100dvh',
    width: '100dvw',
    overflowX: 'auto',
    overflowY: 'scroll',
    '& .MuiPaper-root': {
      height: '100dvh',
      width: '100dvw',
      paddingBottom: '70px',
      margin: 0,
      boxShadow: 24,
      overflowX: 'auto',
      borderRadius: 0
    }
  }
}))

export default ModalApp
