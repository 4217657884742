import React from 'react'
import PropTypes from 'prop-types'
import { InputBaseStyle } from '../../../../ModalApp.style'
import { Box } from '@mui/material'
import { Label } from '../../../../../styled/global.style'
import { TextFieldStyle } from '../../../../../../pages/TradeBlotter/components/FilterTable/FilterTable.style'

const MoreDoublesTradeSecondColumn = ({
  form,
  handelDateChange,
  handelFormChange,
  disabled = false
}) => {
  return (
    <>
      <Box width={'100%'}>
        <Label>Window End *</Label>
        <TextFieldStyle
          type="date"
          variant="outlined"
          value={form.window_end}
          disabled={disabled}
          onChange={event => handelDateChange('window_end', event.target.value)}
        />
      </Box>
      <Box width={'100%'}>
        <Label>Upper Barrier *</Label>
        <InputBaseStyle
          name="upper_barrier"
          placeholder="0.00"
          disabled={disabled}
          value={form.upper_barrier}
          onChange={handelFormChange}
        />
      </Box>
    </>
  )
}

export default MoreDoublesTradeSecondColumn

MoreDoublesTradeSecondColumn.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object,
  handelDateChange: PropTypes.func,
  handelFormChange: PropTypes.func
}
