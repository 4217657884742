import { Button, Typography, FormLabel, styled, InputBase } from '@mui/material'

export const Logo = styled(Typography)(() => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '52px',
  height: '61px',
  width: '52px'
}))

export const SignInButton = styled(Button)(({ theme }) => ({
  width: '252px',
  height: '40px',
  backgroundColor: theme.palette.button.save.primary,
  textAlign: 'center',
  textTransform: 'capitalize',
  fontFamily: 'Nunito',
  fontSize: '14px',
  fontStyle: 'normal',
  padding: '11px 24px',
  letterSpacing: '0px',
  fontWeight: '600',
  color: 'black',
  marginTop: '48px',
  marginBottom: '40px',
  marginLeft: 'auto',
  marginRight: 'auto',
  '&.MuiButtonBase-root': {
    height: 'auto !important',
    lineHeight: 1,
    marginBottom: '0px !important',
    marginTop: '20px'
  },
  '&:hover': {
    backgroundColor: theme.palette.button.save.hover,
    color: 'black'
  },
  '&:disabled': {
    background: theme.palette.button.disable,
    color: theme.palette.svg.clearIcon
  }
}))

export const FromLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '12px',
  fontFamily: 'Nunito'
}))

export const ForgotPasswordLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.button.save.primary,
  marginLeft: 'auto',
  textDecoration: 'underline',
  fontSize: '12px',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.button.save.hover
  }
}))

export const UserNameInputBaseStyle = styled(InputBase)(({ theme, error }) => {
  let border = 'none'

  if (error) {
    border = `1px solid ${theme.palette.button.delete.primary}`
  }

  return {
    border,
    width: '100%',
    height: '32px',
    fontFamily: 'Nunito',
    fontSize: '14px',
    '& .MuiFormControl-root': {
      display: 'block',
      backgroundColor: 'transparent !important',
      padding: 0
    },
    '& input:-webkit-autofill': {
      WebkitBoxShadow: `0 0 0 30 ${theme.palette.separators.primary} inset !important`, // Autofill style adjustment
      '-webkit-text-fill-color': 'inherit',
      transition: 'background-color 5000s ease-in-out 0s'
    },
    '&.Mui-focused': {
      '& fieldset': {
        borderColor: 'transparent' // Remove border color on focus
      }
    },
    '&:hover': {
      '& fieldset': {
        borderColor: 'transparent' // Remove border color on hover
      }
    },
    '&.MuiInputBase-root': {
      borderRadius: '4px',
      padding: '20px 0px !important'
    },
    '& .MuiInputBase-input': {
      backgroundColor: 'transparent !important',
      padding: '0px 12px !important'
    }
  }
})
