import React from 'react'
import { ReactComponent as MakorIcon } from '../../assets/makor.svg'
import { Typography } from '@mui/material'
const Footer = () => {
  return (
    <div style={{ display: 'flex', gap: '11px'}}>
      <MakorIcon />
      <Typography style={{color:"#707079"}}>FX Engine Room | Operated by Makor Group</Typography>
    </div>
  )
}

export default Footer
