import React from 'react'
import PropTypes from 'prop-types'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { FormControl, InputAdornment } from '@mui/material'
import { useState } from 'react'
import { CustomIconButton, CustomOutlinedInput } from './InputPassword.style'

const InputPassword = ({ errorState, formState, handelChange, name }) => {
  const [showPassword, setShowPassword] = useState()

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <>
      <FormControl variant="outlined">
        <CustomOutlinedInput
          name={name}
          autoComplete="off"
          costumeerror={String(!!errorState)}
          value={formState}
          onChange={handelChange}
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment
              position="end"
              style={{ marginRight: '10px', marginBottom: '10px', marginTop: '10px' }}
            >
              <CustomIconButton onClick={handleClickShowPassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </CustomIconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </>
  )
}

InputPassword.propTypes = {
  errorState: PropTypes.string,
  formState: PropTypes.string,
  handelChange: PropTypes.func,
  name: PropTypes.string
}

export default InputPassword
