import React from 'react'
import PropTypes from 'prop-types'
import { TextField, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import './SearchTextField.css'

const SearchTextField = ({ state, handleChange = () => {}, name, handelKeyPress = () => {console.log("hello")} }) => {

  return (
    <TextField
      style={{ minWidth: '205px' }}
      className="SearchTextField"
      placeholder="Search"
      autoComplete="off"
      name={name}
      value={state}
      disabled={false}
      onChange={handleChange}
      onKeyPress={handelKeyPress}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className="SearchIcon" />
          </InputAdornment>
        )
      }}
    />
  )
}

SearchTextField.propTypes = {
  name: PropTypes.string,
  state: PropTypes.string,
  handleChange: PropTypes.func,
  handelKeyPress: PropTypes.func
}

export default SearchTextField
