import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import PropTypes from 'prop-types'

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout
]);

const NightingaleChartChartMmClientData = ({ title, data }) => {
    const chartRef = useRef(null);

  useEffect(() => {
      const myChart = echarts.init(chartRef.current);

      const option = {
        
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            
          left: 'center',
          top: 'bottom',
          
        },
        toolbox: {
          show: false
        },
        series: [
          {
            name: 'Radius Mode',
            type: 'pie',
            radius: "50%",
            roseType: 'radius',
            itemStyle: {
              borderRadius: 5
            },
            label: {
              show: false
            },
            emphasis: {
              label: {
                show: true
              }
            },
            data: data
          },
          
        ]
      };

    myChart.setOption(option);
    return () => {
      myChart.dispose();
    };
  }, [title,data]);

  return <div ref={chartRef} style={{ width: '302px', height: '302px' }} />;
};
NightingaleChartChartMmClientData.propTypes = { params: PropTypes.object ,title:PropTypes.string,data:PropTypes.string}

export default NightingaleChartChartMmClientData;
