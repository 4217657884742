import React from 'react'
import PropTypes from 'prop-types'
import { AutocompleteStyle, TextFieldModalStyle } from '../../../../ModalApp.style'
import { Box, Typography } from '@mui/material'
import { Label } from '../../../../../styled/global.style'
import { OptionContainer } from '../../Trade.stye'
import FlagCurrency from '../../../../../FlagCurrency/FlagCurrency'
import CurrencyLogo from '../../../../../CurrencyLogo/CurrencyLogo'
import PaperAutoComplete from '../../../../../PaperAutoComplete/PaperAutoComplete'
import { TextFieldStyle } from '../../../../../../pages/TradeBlotter/components/FilterTable/FilterTable.style'

const DigitalKnockInKnockOutTradeSecondColumn = ({
  form,
  currencyData,
  currencyInitial,
  handelFormChange,
  handelDateChange,
  disabled = false
}) => {
  return (
    <>
      <Box width={'100%'}>
        <Label>Payout Currency*</Label>
        {Array.isArray(currencyData.data) && (
          <AutocompleteStyle
            onChange={(event, element) => {
              event.target.name = 'payout_currency'
              element = element ?? currencyInitial
              handelFormChange(event, element)
            }}
            size={'small'}
            value={form.payout_currency}
            getOptionLabel={option => option.name}
            options={currencyData.data}
            renderInput={params => (
              <TextFieldModalStyle
                {...params}
                fullWidth
                placeholder="Select Currency"
                disabled={disabled}
              />
            )}
            disabled={disabled}
            renderOption={(props, option) => (
              <OptionContainer {...props} key={option.id}>
                <FlagCurrency countryCode={option.code3} />
                <CurrencyLogo currencyCode={option.name} />
                <Typography style={{ marginLeft: 'auto' }}>{option.name} </Typography>
              </OptionContainer>
            )}
            ListboxComponent={PaperAutoComplete}
          />
        )}
      </Box>
      <Box width={'100%'}>
        <Label>Window End *</Label>
        <TextFieldStyle
          type="date"
          variant="outlined"
          value={form.window_end}
          disabled={disabled}
          onChange={event => handelDateChange('window_end', event.target.value)}
        />
      </Box>
    </>
  )
}

export default DigitalKnockInKnockOutTradeSecondColumn

DigitalKnockInKnockOutTradeSecondColumn.propTypes = {
  form: PropTypes.object,
  currencyData: PropTypes.object,
  currencyInitial: PropTypes.object,
  disabled: PropTypes.bool,
  handelFormChange: PropTypes.func,
  handelDateChange: PropTypes.func
}
