/* eslint-disable */

import React from 'react'
import ChartRow from './Chart/ChartRow'
import { Grid } from '@mui/material'
import MultiLineChart from './Chart/MultiLineChart'

function ClientMmDetails() {
  return (
    <Grid
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px',
        width: '100%'
      }}
      className="container"
    >
      <Grid>
        <ChartRow />
      </Grid>
      <Grid>
        <MultiLineChart />
      </Grid>
    </Grid>
  )
}
export default ClientMmDetails
