/* eslint-disable */
import { Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ClientMmChartDetails from '../ClientMmChartDetails/ClientMmChartDetails.js'
import ClientMmDetails from '../ClientMmDetails/ClientMmDetails.js'
import { routes } from '../../../utils/constants'
import axios from 'axios'
import { useSelector } from 'react-redux'
import FlagCurrency from '../../../components/FlagCurrency/FlagCurrency.js'
import {
  CheckboxEntities,
  FormControlLabelStyle,
  HSBCContainer,
  RBSContainer,
  TraderContainer,
  TypographyCheckBoxForm
} from '../Entities.style.js'
import { ReactComponent as CheckIcon } from '../../../assets/Checkbox_checked.svg'

function EntityDetails({ entityName }) {
  const token = useSelector(state => state.auth.token)
  const [HSBC, setHSBC] = useState([])
  const [RBS, setRBS] = useState([])
  const [entityBseDetails, setEntityBaseDetails] = useState([])
  const [clientName, setClientName] = useState('')
  const [clientMmId, setClientMmId] = useState('')
  const [showEntityDetails, setShowEntityDetails] = useState(true)
  const [userType, setUserType] = useState()

  const getEntitiesDetails = async () => {
    const url = process.env.REACT_APP_SERVER_URL.concat(routes.entities, '/', entityName.accountId)
    try {
      const result = await axios.get(url, { headers: { Authorization: token } })
      setRBS(result.data.rbsData)
      setHSBC(result.data.hsbcData)
      setEntityBaseDetails(result.data.baseDetails)
    } catch (error) {
      console.log(error)
    }
  }

  const handleClientClicked = (clientName, id, userType) => {
    setClientMmId(id)
    setClientName(clientName)
    setShowEntityDetails(false)
    setUserType(userType)
  }
  useEffect(() => {
    getEntitiesDetails()
  }, [])

  let traders = [{ name: 'Max Shepley' }, { name: 'Sourabn Gupta Shepley' }]

  return (
    <Grid
      container
      style={{
        background: '#171719'
      }}
    >
      <Grid
        item
        xl={3}
        lg={4}
        md={5}
        sm={6}
        style={{
          borderRight: '1px solid #39393C',
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: '24px',
          paddingTop: '24px',
          paddingRight: '40px',
          maxHeight: '85dvh'
        }}
        className="container"
      >
        <Grid>
          <Grid>
            <Typography style={{ color: 'white', fontSize: '24px', lineHeight: '1' }}>
              {entityName.name}
            </Typography>
          </Grid>

          <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '20px', gap: '8px' }}>
            <FlagCurrency countryCode={entityBseDetails[0]?.countryCode} />

            <Typography
              style={{
                color: 'white',
                fontSize: '16px',
                color: '#BABEC7',
                maxWidth: '100%',
                lineHeight: '1'
              }}
            >
              {entityBseDetails[0]?.lei}
            </Typography>
          </Grid>

          <Grid
            style={{
              display: 'flex',
              marginTop: '24px',
              flexDirection: 'row',
              gap: '24px'
            }}
          >
            <FormControlLabelStyle
              control={<CheckboxEntities checkedIcon={<CheckIcon />} />}
              label={<TypographyCheckBoxForm>Swap Dealer</TypographyCheckBoxForm>}
              labelPlacement="end"
            />
            <FormControlLabelStyle
              control={<CheckboxEntities checkedIcon={<CheckIcon />} />}
              label={<TypographyCheckBoxForm>PBA</TypographyCheckBoxForm>}
              labelPlacement="end"
            />
          </Grid>
        </Grid>
        <Grid>
          <Grid style={{ marginTop: '32px', paddingBottom: '40px' }}>
            <Typography
              style={{
                color: 'var(--Purple-3, #7373BB)',
                leadingTrim: 'both',
                textEdge: 'cap',
                fontFamily: 'Nunito',
                fontSize: '18px',
                fontStyle: 'normal',
                marginBottom: '16px',
                fontWeight: '500',
                lineHeight: 'normal'
              }}
            >
              Trading Accounts
            </Typography>

            {RBS.length > 0 && (
              <Grid item style={{ marginBottom: '24px' }}>
                <Grid
                  item
                  style={{
                    padding: '16px 24px',
                    background: 'var(--Inputs-on-modal, #39393C)',
                    borderRadius: '4px 4px 0px 0px'
                  }}
                >
                  <Typography
                    style={{
                      color: 'var(--Primary-text, #FFF)',
                      leadingTrim: 'both',
                      textEdge: 'cap',
                      fontFamily: 'Nunito',
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: 'normal'
                    }}
                  >
                    RBS
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{ borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
                >
                  {RBS.map((key, index) => {
                    return (
                      <RBSContainer
                        length={String(RBS.length)}
                        index={String(index)}
                        select={String(clientMmId === key.id)}
                        key={key}
                        onClick={() => handleClientClicked(key.name, key.id, key.type)}
                      >
                        <Typography
                          style={{
                            color: key.id === clientMmId ? '#A4A4FF' : '#FFF',
                            fontSize: '16px',
                            lineHeight: '1',
                            marginBottom: '8px'
                          }}
                        >
                          {key.name}
                        </Typography>
                        <Grid
                          style={{
                            display: 'flex',
                            fontSize: '16px',
                            gap: '4px',
                            lineHeight: '1'
                          }}
                        >
                          <Typography style={{ color: '#707079' }}>{key.type}</Typography>
                          <Typography style={{ color: '#BABEC7' }}>
                            {'|'} {key.location}
                          </Typography>
                        </Grid>
                      </RBSContainer>
                    )
                  })}
                </Grid>
              </Grid>
            )}

            {HSBC.length > 0 && (
              <Grid item style={{ marginBottom: '24px' }}>
                <Grid
                  item
                  style={{
                    padding: '16px 24px',
                    background: 'var(--Inputs-on-modal, #39393C)',
                    borderRadius: '4px 4px 0px 0px'
                  }}
                >
                  <Typography
                    style={{
                      color: 'var(--Primary-text, #FFF)',
                      leadingTrim: 'both',
                      textEdge: 'cap',
                      fontFamily: 'Nunito',
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: 'normal'
                    }}
                  >
                    HSBC
                  </Typography>
                </Grid>

                <Grid
                  item
                  style={{ borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
                >
                  {HSBC.map((key, index) => {
                    return (
                      <HSBCContainer key={key} length={String(HSBC.length)} index={String(index)}>
                        <Grid
                          style={{
                            display: 'flex',
                            marginBottom: '8px',
                            fontSize: '16px',
                            gap: '4px',
                            lineHeight: '1'
                          }}
                        >
                          <Typography style={{ color: '#707079' }}>{key.name}</Typography>
                          <Typography style={{ color: '#BABEC7' }}>
                            {'|'} {key.type}
                          </Typography>
                        </Grid>
                      </HSBCContainer>
                    )
                  })}
                </Grid>
              </Grid>
            )}

            <Grid style={{ marginTop: '32px' }}>
              <Typography
                style={{
                  color: 'var(--Purple-3, #7373BB)',
                  leadingTrim: 'both',
                  textEdge: 'cap',
                  fontFamily: 'Nunito',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  marginBottom: '16px',
                  fontWeight: '500',
                  lineHeight: 'normal'
                }}
              >
                Traders
              </Typography>
            </Grid>
            <Grid>
              {traders.map((key, index) => {
                return (
                  <TraderContainer key={key} index={String(index)} length={String(traders.length)}>
                    <Typography style={{ color: 'var(--Primary-text, #FFF)', fontSize: '14px' }}>
                      {key.name}
                    </Typography>
                  </TraderContainer>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={9} lg={8} md={7} sm={12} style={{ padding: '24px' }}>
        {showEntityDetails ? (
          <ClientMmChartDetails />
        ) : (
          <ClientMmDetails clientMmId={clientMmId} userType={userType} />
        )}
      </Grid>
    </Grid>
  )
}
EntityDetails.propTypes = { params: PropTypes.object, entityName: PropTypes.string }
export default EntityDetails
