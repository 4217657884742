import { styled, InputBase } from '@mui/material'

export const ResetPasswordInput = styled(InputBase)(({ theme, error }) => {
  let border = 'none'

  if (error) {
    border = `1px solid ${theme.palette.button.delete.primary}`
  }

  return {
    border,
    width: '100%',
    fontFamily: 'Nunito',
    fontSize: '14px',
    '& .MuiFormControl-root': {
      display: 'block',
      backgroundColor: 'transparent !important',
      padding: 0
    },
    '& input:-webkit-autofill': {
      WebkitBoxShadow: `0 0 0 30 ${theme.palette.separators.primary} inset !important`, // Autofill style adjustment
      '-webkit-text-fill-color': 'inherit',
      transition: 'background-color 5000s ease-in-out 0s'
    },
    '&.Mui-focused': {
      '& fieldset': {
        borderColor: 'transparent' // Remove border color on focus
      }
    },
    '&:hover': {
      '& fieldset': {
        borderColor: 'transparent' // Remove border color on hover
      }
    },
    '&.MuiInputBase-root': {
      borderRadius: '4px',
      padding: '20px 0px !important'
    },
    '& .MuiInputBase-input': {
      backgroundColor: 'transparent !important',
      padding: '0px 12px !important'
    }
  }
})
