import dayjs from "dayjs"
import { changeTimeLocalToUtc } from "../../../../../helper"
import { DATE_FORMAT } from "../../../../../utils/constants"

export class DealSpot {
  constructor(form) {
    this.deal_type = form.deal_type.name
    this.client_side = form.client_side
    this.client_price = form.client_price
    this.market_maker_price = form.market_maker_price
    this.base_currency = form.currency_pair.substring(0,3)
    this.counter_currency = form.currency_pair.substring(3,6)
    this.client = form.client
    this.market_maker = form.market_maker
    this.trade_date =  changeTimeLocalToUtc(form.trade_date,DATE_FORMAT.REGULAR_DATE_TIME)
    this.quantity = form.quantity
    this.client_value_date = dayjs(form.client_value_date).format(DATE_FORMAT.REGULAR_DATE)
    this.market_maker_value_date = dayjs(form.market_maker_value_date).format(DATE_FORMAT.REGULAR_DATE)
    this.trade_origin = 'FXER'
  }
}
