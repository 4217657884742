import React, { useState } from 'react'
import CardContent from '@mui/material/CardContent'
import { Stack, Box } from '@mui/material'
import {
  Logo,
  FromLabel,
  SignInButton,
  ForgotPasswordLabel,
  UserNameInputBaseStyle
} from './Login.style'
import { ReactComponent as FxEngineLogo } from '../../assets/fx_engine.svg'
import { FormError } from '../../components/ModalApp/ModalApp.style'
import { ERRORS } from '../../utils/constants'
import validator from 'validator'
import { useDispatch } from 'react-redux'
import { loginUser } from '../../redux/slices/Auth/AuthSlice'
import { useNavigate } from 'react-router-dom'
import InputPassword from '../../components/InputPassword/InputPassword'
import { MyCard } from '../../components/styled/global.style'
import { testPasswordRegex } from '../../helper'

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [form, setForm] = useState({ username: '', password: '' })
  const [errors, setErrors] = useState({})

  const handelChange = event => {
    const { name, value } = event.target
    const copyForm = { ...form }
    copyForm[name] = value
    setForm(copyForm)
  }

  const validateForm = () => {
    const errors = {}
    if (form.username.length < 4) {
      errors.username = ERRORS.short('username', 4)
    }
    if (!errors.username && !validator.isAlphanumeric(form.username, 'en-US', { ignore: ' ' })) {
      errors.username = ERRORS.alphaNumeric('username')
    }
    if (form.password.length < 8) {
      errors.password = ERRORS.short('password', 8)
    }
    if (!testPasswordRegex(form.password)) {
      errors.password = ERRORS.password
    }
    setErrors(errors)
    if (Object.keys(errors).length) {
      setErrors(errors)
      return false
    }
    return true
  }

  const disabledButton = () => {
    if (form.username === '' || form.password === '') {
      return true
    }
  }
  const handleForgetPassword = () => {
    navigate('/reset/password')
  }

  const handelSubmit = async () => {
    if (validateForm()) {
      dispatch(loginUser(form, navigate))
    }
  }
  return (
    <MyCard>
      <CardContent>
        <Stack>
          <Logo>
            <FxEngineLogo style={{ height: '100%', width: '100%' }} />
          </Logo>
          <Stack style={{ gap: '24px' }}>
            <Box>
              <FromLabel>Username</FromLabel>
              <UserNameInputBaseStyle
                name="username"
                autoComplete="off"
                value={form.username}
                onChange={handelChange}
                error={!!errors.username}
              />
              {errors.username && (
                <FormError style={{ position: 'relative' }}>{errors.username}</FormError>
              )}
            </Box>
            <Stack>
              {!errors.password && <FromLabel style={{ position: 'relative' }}>Password</FromLabel>}
              <InputPassword
                name="password"
                errorState={errors.password}
                fromState={form.password}
                handelChange={handelChange}
              />
              <Box style={{ display: 'flex', gap: '10px', marginTop: '8px' }}>
                {errors.password && <FormError>{errors.password}</FormError>}
                <ForgotPasswordLabel onClick={handleForgetPassword}>
                  Forgot Password ?
                </ForgotPasswordLabel>
              </Box>
            </Stack>
            <SignInButton onClick={handelSubmit} disabled={disabledButton()}>
              Log in
            </SignInButton>
          </Stack>
        </Stack>
      </CardContent>
    </MyCard>
  )
}

export default Login
